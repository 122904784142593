.HeaderSentRequestCard {
  display: flex;
  flex-direction: column;
  align-self: center;
}
.HeaderSentRequestCardData {
  border: 1px solid lightgray;
  height: 180px;
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 10px;
}
.HeaderSentRequestCardData > p {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 50px;
}
.HeaderSentRequestCardData > p:hover,
.HeaderSentRequestCardDataH3 > h3:hover {
  cursor: pointer;
  text-decoration: underline;
}
.HeaderSentRequestCardData > h5 {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
}
.HeaderSentRequestCardDataH3 > h3 {
  font-size: 14px;
  font-weight: 600;
  color: rgb(241, 162, 15);
  text-align: center;
  margin-top: 10px;
}
