.SidebarESchoolingStatus__ContainerBottomRightShowTeacher {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.rowData {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}
.rowData > h2 {
  font-size: 18px;
  font-weight: 700;
  width: 300px;
  color: gray;
  text-align: end;
}
.rowData > h3 {
  font-size: 20px;
  font-weight: 700;
  width: 500px;
}
.rowData > h4 {
  margin-left: 20px;
  width: 30px;
  color: gray;
}
.subjectButton > button {
  background-color: var(--connected-color);
  border: none;
  color: white;
  font-weight: 700;
  text-transform: inherit;
  border-radius: 4px;
  width: 70px;
  height: 30px;
  margin-left: 10px;
  /* align-items: end; */
}

.subjectButton > button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}
.tableSubjects {
  margin-left: 40px;
  display: flex;
  flex-direction: column;
}
.tableSubjects > table {
  width: 800px;
  margin-top: 20px;
}
.tableSubjects > table > thead > tr > td {
  height: 50px;
}
.tableSubjects > table > thead > tr > th > h3 {
  font-size: 20px;
  font-weight: 700;
  color: var(--connected-color);
}
.tableSubjects > table > tbody > tr > td {
  height: 50px;
}
.tableSubjects > table > tbody >  tr > td > h1,
.tableSubjects > table > tbody >  tr > td > h5,
.tableSubjects > table > tbody >  tr > td > h2,
.tableSubjects > table > tbody >  tr > td > h3,
.tableSubjects > table > tbody >  tr > td > h4 {
  font-size: 15px;
  font-weight: 550;
  text-align: center;
}.tableSubjects > table > tbody >  tr > td > h4{
  color: var(--connected-orange);
  font-weight: 700;
}
.tableSubjects>table {
  border-bottom: 1px solid lightgrey;
  border-collapse: collapse;
  justify-content: center;
  /* text-align: center; */
}
.addNewChildDataHereTop > h3 {
  font-size: 18px;
  color: gray;
  font-weight: 700;
  margin-left: 20px;
}
.addNewChildDataHere {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.addNewChildDataHereName,
.addNewChildDataHereClassNSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.addNewChildDataHereClassNSection {
  margin-top: 20px;
}
.addNewChildDataHere > hr {
  width: 700px !important;
}
.addNewChildDataHere > button,
.addNewChildDataHereData > button {
  background-color: var(--connected-color);
  border: none;
  color: white;
  font-size: 15px;
  font-weight: 700;
  text-transform: inherit;
  border-radius: 4px;
  width: 400px;
  height: 35px;
  margin-top: 20px;
}
.addNewChildDataHere > button:hover,
.addNewChildDataHereData > button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}
.addNewChildDataHereRequestSent > h3 {
  font-size: 15px;
  font-weight: 700;
  color: green;
}
.addNewSubject > h3,
.tableSubjects > h3 {
  font-size: 20px;
  font-weight: 700;
  color: var(--connected-orange);
}
.addNewSubject {
  margin-top: 20px;
}
.addNewChildDataHereData {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
}
.limitReached {
  margin-top: 40px;
}
.limitReached > h3 {
  font-size: 20px;
  font-weight: 700;
  color: var(--connected-orange);
}
#addNewSubjectAccordianTop > .MuiAccordion-root > .MuiAccordionSummary-root {
  background-color: rgb(252, 240, 221);
}
#addNewSubjectAccordianTop
  > .MuiAccordion-root
  > .MuiAccordionSummary-root:hover {
  background-color: rgb(248, 214, 182);
}
