.posts {
  margin-bottom: 5px;
  border: 1px solid lightgray;
  padding: 10px 20px;
  border-radius: 4px;
  background-color: white;
}

.posts__top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.posts__topTop {
  display: flex;
  flex-direction: row;
  width: 500px;
  padding: 10px 0px;
  margin-left: 10px;
}

.posts__topInfo {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.posts__topInfo>h5 {
  font-size: 14px;
  font-weight: 700;
}

.posts__topInfo>h5:hover {
  cursor: pointer;
  text-decoration: underline;
}

.posts__topInfo>p {
  font-size: 11px;
  font-weight: 500;
  color: gray;
}

.posts__topRight {
  padding: 7px;
}

.posts__topRight:hover {
  cursor: pointer;
  background-color: lightgray;
  border-radius: 30px;
}

.moreVertIcon>.MuiSvgIcon-root {
  font-size: 18px;
}

.btnDeletePost>button {
  background-color: rgb(247, 246, 246);
  border: none;
  font-size: 15px;
  font-weight: 600;
  text-transform: inherit;
  border-radius: 3px;
  height: 35px;
  width: 120px;
}

.btnDeletePost>button:hover {
  background-color: rgb(221, 221, 221);
}

.posts__middle {
  width: 550px;
}

.posts__middle>p,
.postText>p {
  font-size: 16px;
  max-width: 500px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.postText>h3 {
  font-size: 16px;
  margin-left: 10px;
  /* margin-top: -10px; */
  margin-bottom: 10px;
  color: var(--connected-color);
}

.postText>h3:hover {
  cursor: pointer;
  text-decoration: underline;
}

.postOneImg {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.postOneImg>img {
  cursor: pointer;
  max-width: 550px;
  max-height: 300px;
  margin-left: 10px;
  object-fit: contain;
}

.imageBig>img {
  max-width: 700px;
  max-height: 700px;
  object-fit: contain;
}

.posts__bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 10px;
  width: 500px;
}

.posts__option {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px;
  cursor: pointer;
}

.posts__option>p:hover {
  text-decoration: underline;
}

.posts__option>p {
  font-size: 13px;
  font-weight: 600;
  padding-left: 7px;
}

.posts__option>.MuiSvgIcon-root {
  font-size: 20px;
}

.likedCommentTrue {
  color: var(--connected-color);
}

.likesCommentsNumbers {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  /* border-top: 1px solid lightgray; */
  padding-bottom: 10px;
  width: 500px;
  margin-top: 20px;
}

.likesCommentsNumbers>p {
  padding-left: 40px;
  padding-right: 20px;
  font-size: 14px;
  font-weight: 600;
}

.likesCommentsNumbers>p:hover {
  cursor: pointer;
  text-decoration: underline;
}

.commentBoxBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.commentBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  width: 550px;
  border-top: 1px solid lightgray;
}

.commentSendIcon>.MuiSvgIcon-root {
  width: 20px;
  height: 20px;
}

.commentSendIcon {
  width: 25px;
  height: 25px;
  padding-left: 5px;
  padding-top: 5px;
}

.commentSendIcon:hover {
  cursor: pointer;
}

.commentBox>.MuiTextField-root {
  width: 400px;
}

.commentSendIcon>.MuiSvgIcon-root:hover {
  color: var(--connected-color);
}

.friendsCommentsBlock {
  margin-top: 10px;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid lightgray;
  width: 450px;
  padding-top: 10px;
}

.friendsCommentNameAvatar,
.friendsComment {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.friendsComment {
  justify-content: space-between;
}

.friendsCommentText {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.friendsCommentText>p {
  font-size: 13px;
  max-width: 400px;
}

.friendsCommentText>h3 {
  font-size: 16px;
  font-weight: 600;
}

.friendsCommentText>h5 {
  font-size: 14px;
  font-weight: 500;
}

.commentoRName:hover {
  cursor: pointer;
  text-decoration: underline;
}

.deletePostModal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.deletePostModalText>h4 {
  font-size: 20px;
  font-weight: 600;
  color: black;
}

.deletePostModalButtons {
  margin-top: 20px;
}

.deletePostModalButtons>button {
  background-color: #1976d2;
  color: white;
  border: 1px solid #1976d2;
  font-size: 15px;
  font-weight: 600;
  text-transform: inherit;
  border-radius: 3px;
  height: 35px;
  width: 80px;
  margin-left: 10px;
  margin-right: 10px;
}

.deletePostModalButtons>button:hover {
  background-color: white;
  color: #1976d2;
  border: 1px solid #1976d2;
}

.likerNames {
  display: flex;
  flex-direction: column;
  padding: 10px;
  max-width: 300px;
  max-height: 500px;
  overflow: scroll;
  overflow-x: hidden;
  background-color: #dedee0;
  border-radius: 4px;
}

.likerNames>h5 {
  font-size: 13px;
  font-weight: 500;
  padding: 3px;
}

.likerNames>h5:hover {
  cursor: pointer;
  text-decoration: underline;
}

.commentDelete>.MuiSvgIcon-root {
  font-size: 14px;
}

.commentDelete>.MuiSvgIcon-root:hover {
  color: #1976d2;
  cursor: pointer;
  font-size: 18px;
}

.btnDeleteComment>button {
  background-color: rgb(247, 246, 246);
  border: none;
  font-size: 12px;
  font-weight: 600;
  text-transform: inherit;
  border-radius: 3px;
  height: 30px;
  width: 120px;
}

.btnDeleteComment>button:hover {
  background-color: rgb(221, 221, 221);
}

.loadMoreComments {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loadMoreComments>h3 {
  font-size: 14px;
  font-weight: 600;
  color: black;
  padding: 10px;
}

.loadMoreComments>h3:hover {
  cursor: pointer;
  text-decoration: underline;
  color: var(--connected-color);
}

.noMoreComments>h4 {
  font-size: 16px;
  font-weight: 600;
  color: var(--connected-orange);
  text-align: center;
  padding: 10px;
}

.postDeleted {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  background-color: white;
  border: 1px solid lightgray;
  margin-bottom: 5px;
  border-radius: 5px;
}

.postDeleted>h4 {
  font-size: 20px;
  font-weight: 700;
  color: var(--connected-orange);
}

.imgSlide {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .imgSlide {
  .rsis-image {
    background-size: contain !important;
  }
} */