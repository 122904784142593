.userfriends {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 5px; */
}
.fridnsHeadr {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.userfriends > hr {
  width: 100%;
  border: 1px solid var(--connected-color);
  margin-top: 10px;
  margin-bottom: 10px;
}
.userfriends__top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.userfriends__top > h2 {
  /* margin-bottom: 30px; */
  font-size: 22px;
  font-weight: 700;
  margin-left: 20px;
  color: var(--connected-color);
}
.followErsIng {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 50px;
  padding-left: 50px;
  width: 300px;
}
.followErsIng > h3 {
  font-size: 15px;
  font-weight: 600;
}
.followErsIng > h3:hover {
  cursor: pointer;
  text-decoration: underline;
}
#gridContainer {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  grid-gap: 8px;
  padding: 10px;
}
.noConnections > h3 {
  font-size: 20px;
  font-weight: 600;
  color: var(--connected-orange);
}
.cardButton > button {
  border: none;
  font-weight: 600;
  text-transform: inherit;
  border-radius: 4px;
  width: 100px;
  height: 30px;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 700;
}

.buttonOrange > button {
  background-color: var(--connected-orange);
  color: white;
}
.buttonOrange > button:hover {
  background-color: white;
  color: var(--connected-orange);
  border: 1px solid var(--connected-orange);
}

.buttonBlue > button {
  background-color: var(--connected-color);
  color: white;
}
.buttonBlue > button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}
.buttonGreen > button {
  background-color: green;
  color: white;
}
.buttonGreen > button:hover {
  background-color: white;
  color: green;
  border: 1px solid green;
}

.dialogFollowErsIng {
  width: 580px;
  height: 700px;
  padding: 10px;
}
.followerFriends,
.followerSchools,
.followingFriends,
.followingSchools {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 1px;
  width: 550px;
}
.showMoreBtnFrnds > button{
  background-color: var(--connected-color);
  border: 1px solid var(--connected-color);
  color: white;
  font-weight: 760;
  text-transform: inherit;
  border-radius: 2px;
  width: 250px;
  height: 30px;
  margin-top: 5px;
  margin-right: 5px;
}
.showMoreBtnFrnds > button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}
.showMoreBtnFrnds>h4{
  font-size: 20;
  font-weight: 700;
  color: black;
  margin-top: 30;
  margin-bottom: 30;
}
.showMoreBtnFrnds>h4:hover{
  cursor: pointer;
  font-size: 20;
  font-weight: 700;
  color: #2e81f4;
}
.noMorePostsNew > h4{
  font-size: 20;
  font-weight: 700;
  color: orange;
}
.showMoreBtnFrndsNew>button{
  background-color: #2e81f4;
  border: 1px solid #2e81f4;
  color: white;
  font-weight: 760;
  text-transform: inherit;
  border-radius: 2px;
  width: 250px;
  height: 30px;
  margin-top: 5px;
  margin-right: 5px;
}
.showMoreBtnFrnds > button:hover {
  background-color: white;
  color: #2e81f4;
  border: 1px solid #2e81f4;
}