.recievedrequest {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  justify-content: space-between;
}
.recievedrequest__left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.recievedrequest__left > h5 {
  font-size: 15px;
  font-weight: 650;
  margin-left: 10px;
}
.recievedrequest__left > h5:hover {
  cursor: pointer;
  text-decoration: underline;
}
.recievedrequest__leftavatar {
  cursor: pointer;
}
.recievedrequest__right {
  margin-right: 10px;
  display: flex;
  flex-direction: row;
}
.recievedrequest__right > button {
  background-color: var(--connected-color);
  border: none;
  color: white;
  font-weight: 600;
  font-size: 14px;
  text-transform: inherit;
  border-radius: 2px;
  width: 60px;
  height: 27px;
  margin-left: 7px;
}
.recievedrequest__right > button:hover {
  background-color: white;
  border: 1px solid var(--connected-color);
  color: var(--connected-color);
}
