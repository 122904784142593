.schoolverifiedmembers {
  /* display: flex;/ */
  /* flex-direction: column; */
  background-color: var(--connected-background);
  height: 100%;
  /* width: 100%; */
}

.schoolverifiedmembers__container {
  width: 1200px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid lightgray;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 10px;
}

.schoolverifiedmembers__container>hr {
  width: 100% !important;
  margin-bottom: 20px;
  margin-top: 20px;
  border: 1px solid var(--connected-color);
}

.schoolverifiedmembers__containerTop {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.schoolverifiedmembers__containerTop>h3 {
  font-size: 22px;
  font-weight: 800;
  color: var(--connected-color);
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.schoolverifiedmembers__containerTop>h5 {
  font-size: 14px;
  font-weight: 700;
  color: gray;
}

.schoolverifiedmembers__containerTop>h4 {
  font-size: 16px;
  font-weight: 800;
  color: gray;
}

.schoolverifiedmembers__containerTop>hr {
  width: 100% !important;
  margin-bottom: 20px;
  margin-top: 20px;
  border: 1px solid var(--connected-color);
}

.schoolverifiedmembers__containerBottom {
  display: flex;
  flex-direction: row;
}

.schoolverifiedmembers__containerLeft {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  position: sticky;
  top: 80px;
}

.schoolStaff {}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: var(--connected-color);
  color: white;
  height: 35px;
}

.title>h2 {
  font-size: 22px;
  font-weight: 700;
  margin-left: 10px;
}

.title>.MuiSvgIcon-root {
  font-size: 26px !important;
}

.schoolStaffTitles {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 270px;
  margin-left: 15px;
}

.title_subtitles {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.schoolStaffTitlesTeachingMedium,
.schoolParentsTitlesMedium,
.schoolStudentsTitlesMedium {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 245px;
  margin-left: 20px;
}

.title_subtitlesMediums {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.title_subtitlesMediums>h4 {
  font-size: 18px !important;
  font-weight: 700 !important;
  color: var(--connected-color);
}

.title_subtitlesMediums>.MuiSvgIcon-root {
  font-size: 20px !important;
  color: var(--connected-color);
}

.title_subtitlesMediumsClass {
  width: 100%;
  margin: 1px;
}

.title_subtitlesMediumsClass>h5 {
  margin-left: 10px;
}

.title_subtitlesMediumsClass:hover {
  color: var(--connected-color);
}

.schoolStaffTitlesTeachingMediumClass,
.schoolParentsTitlesMediumClass,
.schoolStudentsTitlesMediumClass {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 225px;
  margin-left: 15px;
}

.title_subtitlesMediumsClassSec {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 200px;
  margin-left: 15px;
}

.EngMediumClassNSection,
.MarMediumClassNSection {
  display: flex;
  flex-direction: column;
}

.title_subtitlesMediumsClassSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
  width: 100%;
}

.title_subtitlesMediumsClassSection :hover {
  color: var(--connected-color) !important;
  /* text-decoration: underline; */
}

.title_subtitlesMediumsClass {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.options>.MuiSvgIcon-root {
  font-size: 25px;
  margin-left: 10px;
  margin-right: 10px;
}

.suboptions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.suboptions>.MuiSvgIcon-root {
  font-size: 25px;
  margin-left: 10px;
  margin-right: 11px;
}

.schoolverifiedmembers__containerRight {
  width: 900px;
}

/* Verification Requests Title */
.verificationrequests__topData {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

.verificationrequests__topData>h1 {
  font-size: 25px;
  font-weight: 800;
  color: var(--connected-orange);
  text-align: center;
  /* margin-bottom: 30px; */
}

.verificationrequests__topData>p {
  font-size: 12px;
  font-weight: 800;
  color: gray;
  text-align: center;
  /* margin-bottom: 30px; */
}

.verificationrequests__topData>h3 {
  font-size: 16px;
  font-weight: 800;
  color: black;
  text-align: center;
  /* margin-bottom: 30px; */
}

/* Verification Requests Title */
.active {
  color: var(--connected-color);
}

.show {
  display: flex;
}

.hidden {
  display: none;
}

.schoolverifiedmembers__containerRightParentsGuardians,
.schoolverifiedmembers__containerRightStudents,
.schoolverifiedmembers__containerRightPrincipal,
.schoolverifiedmembers__containerRightTeachingStaff,
.schoolverifiedmembers__containerRightNonTeachingStaff {
  display: flex;
  flex-direction: column;
}

.noStudentRequest {
  font-size: 20px;
  font-weight: 700;
  color: var(--connected-orange);
  text-align: center;
  margin-top: 30px;
}

/* .mainDropdown {
  background-color: var(--connected-color);
  color: white;
} */