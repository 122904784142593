/* TEACHER */
.createnewaccountinfo__containerTeachers {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.createnewaccountinfo__containerTeachers > h3 {
  font-size: 24px;
  font-weight: 700;
  color: var(--connected-color);
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}
.h5__1TeacherClass > h5,
.h5__1TeacherSection > h5,
.h5__1TeacherSubject > h5,
.h5__2TeacherClass > h5,
.h5__2TeacherSection > h5,
.h5__2TeacherSubject > h5,
.h5__3TeacherClass > h5,
.h5__3TeacherSection > h5,
.h5__3TeacherSubject > h5,
.h5__4TeacherClass > h5,
.h5__4TeacherSection > h5,
.h5__4TeacherSubject > h5,
.h5__5TeacherClass > h5,
.h5__5TeacherSection > h5,
.h5__5TeacherSubject > h5,
.h5__6TeacherClass > h5,
.h5__6TeacherSection > h5,
.h5__6TeacherSubject > h5,
.h5__1TeacherClassMedium > h5,
.h5__2TeacherClassMedium > h5,
.h5__3TeacherClassMedium > h5,
.h5__4TeacherClassMedium > h5,
.h5__5TeacherClassMedium > h5,
.h5__6TeacherClassMedium > h5 {
  font-size: 13px;
  font-weight: 600;
  color: red;
  margin-left: 20px;
  text-align: justify;
  margin-bottom: 3px;
}
.TeachersProfilePic {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
}
.TeachersProfilePicleft > h2 {
  font-size: 20px;
  font-weight: 700;
  color: gray;
  margin-right: 100px;
}
.TeachersProfilePicRight {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.TeachersProfilePicRight > button {
  color: white;
  background-color: gray;
  cursor: pointer;
  border: 1px solid gray;
  font-size: 13px;
  font-weight: 700;
  border-radius: 4px;
  text-transform: inherit;
  width: 120px;
  height: 25px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
}
.TeachersProfilePicRight > button:hover {
  color: gray;
  background-color: white;
}
.teacherClassnSubjects > h3 {
  font-size: 16px;
  font-weight: 700;
  color: var(--connected-color);
  margin-top: 30px;
  text-align: center;
}
.teacherClassnSubjects > h5 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
}
.ClassnSubject {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.ClassnSubject > p {
  font-size: 16px;
  font-weight: 800;
  color: var(--connected-orange);
  /* margin-left: 20px; */
  margin-right: 10px;
}
.ClassnSubjectRow {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* justify-content: center; */
}
.ClassnSubjectRow > button {
  font-size: 17px;
  font-weight: 700;
  color: var(--connected-orange);
  margin-left: 10px;
  background-color: white;
  border: none;
  outline: none;
}
.ClassnSubjectRow > button:hover {
  cursor: pointer;
  text-decoration: underline;
}
.TeacherClassNum,
.TeacherClassSubject {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.TeacherClassSubject {
  margin-left: 20px;
}
.createnewaccountinfo__containerTeachers > button,
.mainTeachers > button {
  color: white;
  background-color: var(--connected-color);
  cursor: pointer;
  border: 1px solid var(--connected-color);
  font-size: 16px;
  font-weight: 700;
  text-transform: inherit;
  border-radius: 3px;
  height: 35px;
  margin-left: auto;
  margin-right: auto;
}
.createnewaccountinfo__containerTeachers > button,
.mainTeachers > button {
  width: 400px;
  margin-top: 30px;
}
.mainTeachers > button {
  width: 200px;
  margin-top: 10px;
}
.createnewaccountinfo__containerTeachers > button:hover,
.mainTeachers > button:hover {
  color: var(--connected-color);
  background-color: white;
  border: 1px solid var(--connected-color);
}
.mainTeachers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mainTeachers > h5 {
  color: var(--connected-orange);
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
.h5__TeacherSchool {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.noAvailable_teacher {
  width: 670px;
  justify-content: center;
}
.noAvailable_teacher > h5 {
  font-size: 13px;
  font-weight: 400;
  color: var(--connected-orange);
  text-align: center;
}
.pricipal {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px;
  border: 1px solid lightgray;
  border-radius: 15px;
  padding: 10px;
}
.pricipalYesNoOptions,
.teacherYesNoOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 50px;
  /* background-color: aquamarine; */
}
.pricipalYesNo,
.teacherYesNo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.teachersData {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px;
  border: 1px solid lightgray;
  border-radius: 15px;
  padding: 10px;
}
.teacherDataSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.eitherOr {
  display: flex;
  margin-top: 20px;
}
.eitherOr > h3 {
  font-size: 20px;
  font-weight: 700;
  color: red;
  text-align: center;
}
/* TEACHER */
