.FindToFollowAccounts {
  display: flex;
  flex-direction: column;
  background-color: white;
}
.FindToFollowAccountsSchools > h3,
.FindToFollowAccountsUsers > h3 {
  font-size: 20px;
  font-weight: 700;
  color: black;
  margin: 10px;
  margin-left: 50px;
  margin-bottom: 20px;
}
.FindToFollowAccountsUsers > h3 {
  margin-top: 40px;
}
.FindToFollowAccountsSchools > hr,
.FindToFollowAccountsUsers > hr {
  border: 1px solid lightgrey;
  width: 1150px !important;
  margin-bottom: 20px;
}
.FindToFollowAccountsUsers {
  display: flex;
  flex-direction: column;
}
