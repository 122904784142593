/* * {
  font-family: "Potta One", cursive;
} */

.app {
  --connected-color: #2e81f4;
  --connected-background: #eff2f5;
  --connected-orange: rgb(241, 162, 15);
}
/* .app__body {
  display: flex;
  flex-direction: row;
  width: 1300px;
  background-color: var(--connected-background);
  height: 100%;
  width: 100%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.app__bodyLeft {
  width: 300px;
  padding-top: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
}
.app__bodyRight {
  width: 600px;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
} */
