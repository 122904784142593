.SidebarNoticeBoardAccordian {
  margin-bottom: 20px;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}

#acoordianNoticeBoard > .MuiAccordion-root > .MuiAccordionSummary-root:hover {
  background-color: rgb(219, 219, 219);
} 
.AccordionSummary {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.AccordionSummary__SentOn {
  width: 220px;
}
.AccordionSummary__SentOn > h3 {
  font-size: 14px;
  font-weight: 500;
  color: black;
  padding-left: 20px;
}
.AccordionSummary__Subject {
  width: 400px;
  height: 20px;
  overflow: scroll;
  overflow-y: hidden;
  overflow-x: hidden;
}
.AccordionSummary__Subject > h3 {
  font-size: 14px;
  font-weight: 500;
  color: black;
}
.AccordionSummary__Button > button {
  background-color: var(--connected-color);
  border: none;
  color: white;
  font-weight: 700;
  text-transform: inherit;
  border-radius: 4px;
  width: 60px;
  height: 25px;
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
}
.AccordionSummary__Button > button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}
.AccordionDetails {
  width: 750px;
  border: 1px solid gray;
  border-radius: 10px;
  padding: 10px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  /* background-color: red; */
}
.AccordionDetails > hr {
  border: 1px solid gray;
  width: 700px !important;
  align-items: center;
}
.AccordionDetails > h3 {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
.AccordionDetails > h1 {
  font-size: 24px;
  font-weight: 700;
  margin-top: 30px;
  text-align: center;
  margin-bottom: 30px;
}
.AccordionDetails > h4 {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  color: black;
}
.AccordionDetails > h2 {
  /* margin-top: 20px; */
  font-size: 16px;
  font-weight: 700;
  color: gray;
  margin-left: 20px;
}
.AccordionDetails > p {
  margin-top: 30px;
  margin-left: 20px;
  width: 700px;
  text-align: justify;
}
.AccordionDetails > h5 {
  font-size: 18px;
  font-weight: 700;
  margin-top: 30px;
  /* text-align: end; */
  /* margin-right: 100px; */
  margin-left: 70px;
  margin-bottom: 30px;
}
