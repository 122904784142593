.SidebarNoticeBoard {
  /* display: flex; */
  /* flex-direction: column; */
  background-color: var(--connected-background);
  height: 100vh;
  /* width: 100%; */
  overflow-y: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.SidebarNoticeBoard::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.SidebarNoticeBoard {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.SidebarNoticeBoard__Container {
  width: 1200px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid lightgray;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 10px;
  height: 100%;
}

.SidebarNoticeBoard__Container>hr {
  width: 100% !important;
  margin-bottom: 20px;
  margin-top: 20px;
  border: 1px solid var(--connected-color);
}

.SidebarNoticeBoard__ContainerTop {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SidebarNoticeBoard__ContainerTop>h3 {
  font-size: 22px;
  font-weight: 800;
  color: var(--connected-color);
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.SidebarNoticeBoard__ContainerTop>hr {
  width: 100% !important;
  margin-bottom: 20px;
  margin-top: 20px;
  border: 1px solid var(--connected-color);
}

.SidebarNoticeBoard__ContainerTop>h2 {
  font-size: 22px;
  font-weight: 800;
  color: var(--connected-orange);
}

.SidebarNoticeBoard__ContainerBottom {
  display: flex;
  flex-direction: row;
}

.SidebarNoticeBoard__ContainerBottomLeft {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  position: sticky;
  top: 80px;
  /* background-color: rebeccapurple; */
}

.SidebarNoticeBoard__containerLeft__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  padding: 5px;
  cursor: pointer;
  padding-left: 20px;
  color: gray;
  /* border-radius: 3px; */
}

.SidebarNoticeBoard__containerLeft__title:hover {
  background-color: var(--connected-color);
  color: white;
}

.SidebarNoticeBoard__containerLeft__title>h2 {
  font-size: 20px;
  font-weight: 700;
  margin-left: 10px;
}

.SidebarNoticeBoard__ContainerBottomRight {
  width: 850px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sendNotice__ToAndValidity {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 200px;
  margin-bottom: 20px !important;
}

.sendNotice {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-top: 40px;
  margin-right: 200px;
  /* background-color: red; */
}

.sendNotice>h4 {
  font-size: 16px;
  font-weight: 700;
  color: gray;
  text-align: center;
}

.sendNotice>h3 {
  font-size: 22px;
  font-weight: 700;
  /* color: gray; */
  margin-top: 40px;
  text-align: center;
}

.sendNotice__subject {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
}

.sendNotice__date {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.sendNotice__date>h4 {
  font-size: 18px;
  font-weight: 700;
  color: gray;
  margin-right: 20px;
}

.sendNotice__date>h2 {
  font-size: 18px;
  font-weight: 700;
  /* color: gray; */
  margin-right: 20px;
}

.sendNotice__from {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.sendNotice__validtill>input,
.sendNotice__date>input {
  border: 1px solid lightgray;
  font-size: 14px;
  color: gray;
  font-weight: 700;
  border-radius: 3px;
  width: 200px;
  height: 40px;
  cursor: pointer;
  padding-left: 20px;
}

.sendNotice__buttonSend {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sendNotice__buttonSend>h3 {
  font-size: 20px;
  font-weight: 700;
  color: green;
  margin-top: 10px;
}

.sendNotice__buttonSend>button {
  background-color: var(--connected-color);
  border: none;
  color: white;
  font-weight: 700;
  text-transform: inherit;
  border-radius: 4px;
  width: 150px;
  height: 40px;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 700;
}

.sendNotice__buttonSend>button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}

.sendNotice__noticeBody {
  margin-top: 20px;
  /* margin-right: 20px; */
}

.active {
  color: var(--connected-color);
}

.sentNotice {
  width: 800px;
}

.notCreatedYet>h3 {
  font-size: 20p;
  font-weight: 700;
  color: var(--connected-orange);
  text-align: center;
}

.sendNoticeBlock {
  margin-left: 80px;
}

.limitNotice {
  margin-top: 20px;
  width: 800px;
  margin-right: 40px;
}

.limitNotice>h4 {
  font-size: 18px;
  font-weight: 700;
  color: red;
  text-align: center;
}

.limitNoticeSent>h4 {
  font-size: 18px;
  font-weight: 700;
  color: red;
  text-align: center;
  margin-bottom: 20px;
}