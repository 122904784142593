.complaintAndFeedback,
.complainBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.complainBox>.MuiTextField-root {
    width: 500px;
}

.complainBox>button {
    color: white;
    background-color: var(--connected-color);
    cursor: pointer;
    border: 1px solid var(--connected-color);
    font-size: 13px;
    font-weight: 700;
    text-transform: inherit;
    border-radius: 4px;
    width: 200px;
    height: 35px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 600;
}

.complainBox>button:hover {
    color: var(--connected-color);
    background-color: white;
    border: 1px solid var(--connected-color);
}

.Complaintitle {
    font-size: 17px;
    font-weight: 600;
    color: grey;
}

.complaintAndFeedback>.MuiAccordion-root>.MuiAccordionSummary-root {
    background-color: rgb(248, 229, 229);
    width: 600px;
}

.complainAcco {
    margin-bottom: 10px;
    width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.complaintAndFeedback>.MuiAccordion-root>.MuiAccordionSummary-root:hover {
    background-color: rgb(247, 224, 190);
}

.complainAcco>.MuiAccordion-root>.MuiAccordionSummary-root {
    background-color: rgb(248, 229, 229);
}

.suggBody {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.complainAcco>.MuiAccordion-root>.MuiAccordionSummary-root:hover {
    background-color: rgb(247, 224, 190);
}

.compSuggTitle {
    display: flex;
    flex-direction: column;
}

.compSuggTitle>h3 {
    font-size: 18px;
    font-weight: 600;
    color: var(--connected-orange);
    padding: 20px;
    padding-left: 60px;
}

.AccoHead {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.userAbout {
    display: flex;
    flex-direction: row;
    margin: 10px 0;
}

.userAbout>p {
    font-size: 17px;
    font-weight: 700;
    color: var(--connected-color);
    width: 120px;
    text-align: right;
}

.userAbout>h3 {
    font-size: 18px;
    font-weight: 700;
    width: 600px;
    margin-left: 20px;
}

.suggComp {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.suggComp>p {
    width: 50px;
    font-size: 18px;
    font-weight: 700;
    text-align: right;
    margin-right: 10px;
    color: var(--connected-orange);
}

.compData>h4 {
    width: 450px;
    font-size: 18px;
    font-weight: 400;
    color: black;
    /* margin-left: 20px; */
}

.compData>h2 {
    font-size: 18px;
    font-weight: 700;
}

.complainBox>h3 {
    margin: 20px;
}