.schoolfollowingSchools {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
  padding: 10px;
}
.userfriends__topNew > h2{
    font-size: 20;
    font-weight: 700;
    color: #2e81f4;
}