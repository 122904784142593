.friendsprofile {
  background-color: var(--connected-background);
}
.friendsprofile__body {
  display: flex;
  flex-direction: column;
  width: 1000px;
  height: 100%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.friendsprofile__bodyTop {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.friendsprofile__bodyBottom {
  display: flex;
  flex-direction: row;
  width: 900px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.friendsprofile__bodyBottomLeft {
  width: 300px;
  position: sticky;
  top: 80px;
}
.friendsprofile__bodyBottomRight {
  width: 600px;
  margin-left: 5px;
}
#friendsprofile__bodyTopNavbarAppBar > .MuiAppBar-root {
  position: sticky;
  top: 70px;
  z-index: 10;
  width: 1000px;
}
.MuiTabs-root {
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  color: var(--connected-color);
}
.MuiTab-root {
  font-size: 16px !important;
  font-weight: 600 !important;
  text-transform: inherit !important;
}
.MuiTabs-indicator {
  border: 1px solid var(--connected-color) !important;
  background-color: var(--connected-color) !important;
}
