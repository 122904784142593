.SidebarESchoolingStatusAddPrincipal {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.principal {
  width: 600px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid lightgray;
  height: 50px;
  justify-content: center;
  border-radius: 4px;
}
.SidebarESchoolingStatusAddPrincipal > button {
  background-color: var(--connected-color);
  border: none;
  color: white;
  font-weight: 700;
  text-transform: inherit;
  border-radius: 3px;
  width: 350px;
  height: 35px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 700;
}
.SidebarESchoolingStatusAddPrincipal > button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}
.SidebarESchoolingStatusAddPrincipal > h5 {
  /* margin-top: 10px; */
  font-size: 18px;
  font-weight: 700;
  color: green;
}
.princiSchoolName {
  width: 700px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  margin-top: 20px;
}
.princiSchoolName > h2 {
  width: 150px;
  font-size: 20px;
  font-weight: 700;
}
.princiSchoolName > h3 {
  width: 50px;
  font-size: 22px;
  font-weight: 700;
}
.princiSchoolName > h4 {
  width: 600px;
  font-size: 20px;
  font-weight: 700;
}
