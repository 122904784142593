/* PRINCIPAL */
.SchoolVerifiedMembersPrincipal__containerRightPrincipalData {
  padding: 10px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 600px;
  margin-left: 100px;
}
.SchoolVerifiedMembersPrincipal__containerRightPrincipalDataName {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.SchoolVerifiedMembersPrincipal__containerRightPrincipalDataName > h3 {
  margin-left: 20px;
  font-size: 16px;
  font-weight: 700;
}
.SchoolVerifiedMembersPrincipal__containerRightPrincipalDataButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.SchoolVerifiedMembersPrincipal__containerRightPrincipalDataButtons > button {
  background-color: var(--connected-color);
  border: none;
  color: white;
  font-weight: 700;
  text-transform: inherit;
  border-radius: 4px;
  width: 70px;
  height: 30px;
  margin-left: 10px;
}
.SchoolVerifiedMembersPrincipal__containerRightPrincipalDataButtons
  > button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}
/* PRINCIPAL */
