.SchoolUpcomingEventData {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.SchoolUpcomingEventData_AccordionSummary {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.summary_Data {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
}
.summary_Data > h3 {
  font-size: 14px;
  font-weight: 600;
  width: 100px;
  text-align: right;
  color: gray;
}
.summary_Data > h5 {
  font-size: 14px;
  font-weight: 600;
  width: 20px;
  text-align: center;
}
.nameEvent > h4 {
  font-size: 15px;
  font-weight: 700;
  width: 250px;
  height: 22px;
  padding-top: 2px;
  color: black;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
}
.dateEvent > h4 {
  font-size: 15px;
  font-weight: 700;
  width: 150px;
  height: 22px;
  padding-top: 2px;
  color: black;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
}
.SchoolUpcomingEventData > .MuiAccordion-root > .MuiAccordionSummary-root {
  background-color: rgb(229, 229, 230);
  height: 30px;
}
.SchoolUpcomingEventData
  > .MuiAccordion-root
  > .MuiAccordionSummary-root:hover {
  background-color: rgb(210, 210, 212);
}
.SchoolUpcomingEventData_AccordionSummary > button {
  background-color: var(--connected-color);
  border: 1px solid var(--connected-color);
  color: white;
  text-transform: inherit;
  border-radius: 2px;
  width: 60px;
  height: 25px;
  font-size: 14px;
  font-weight: 600;
}
.SchoolUpcomingEventData_AccordionSummary > button:hover {
  background-color: white;
  border: 1px solid var(--connected-color);
  color: var(--connected-color);
}
.SchoolUpcomingEventData_AccordionDetails {
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  width: 800px;
  border-radius: 10px;
  padding: 10px;
}
.eventRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: 10px;
}
.alignCenter {
  align-items: center;
}
.eventRow > h3 {
  font-size: 14px;
  font-weight: 700;
  width: 130px;
  margin-left: 20px;
  text-align: right;
  color: var(--connected-color);
}
.eventRow > h5 {
  font-size: 14px;
  font-weight: 700;
  width: 30px;
  text-align: center;
}
.eventRow > h4 {
  font-size: 15px;
  font-weight: 500;
  max-width: 600px;
  color: black;
}
.eventRowChiefGuests {
  display: flex;
  flex-direction: column;
}
.eventRowChiefGuests > h4 {
  font-size: 15px;
  font-weight: 500;
  color: black;
  margin-left: 10px;
  margin-bottom: 10px;
}
