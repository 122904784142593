.SchoolVerifiedMembersTeachers {
  display: flex;
  flex-direction: column;
  margin-left: 60px;
}

.SchoolVerifiedMembersTeachers__containerRightTeachingStaffData {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.verMemData {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SchoolVerifiedMembersTeachers__containerRightTeachingStaffDataName {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SchoolVerifiedMembersTeachers__containerRightTeachingStaffData>p {
  font-size: 17px;
  font-weight: 700;
  color: var(--connected-orange);
  width: 230px;
}

.SchoolVerifiedMembersTeachers__containerRightTeachingStaffDataName>h3 {
  font-size: 14px;
  font-weight: 700;
  width: 300px;
  margin-left: 10px;
  color: black;
}

.SchoolVerifiedMembersTeachers__containerRightTeachingStaffDataButtons>button {
  background-color: var(--connected-color);
  border: none;
  color: white;
  font-weight: 700;
  text-transform: inherit;
  border-radius: 3px;
  width: 70px;
  height: 30px;
  margin-left: 10px;
}

.SchoolVerifiedMembersTeachers__containerRightTeachingStaffDataButtons>button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}

.verifiedMember>h3 {
  font-size: 16px;
  font-weight: 700;
  color: gray;
  width: 500px;
}

.SchoolVerifiedMembersTeachers>hr {
  width: 700px !important;
  border: 1px solid lightgray !important;
  /* margin-left: 150px; */
  margin-top: 20px;
  margin-bottom: 20px;
}