.createschoolaccountinfo {
  height: 100%;
  display: grid;
  background-color: var(--connected-background);
  place-items: center;
}
.createschoolaccountinfo__container {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 80px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.createschoolaccountinfo__container > h1 {
  font-size: 50px;
  font-weight: 700;
  color: var(--connected-color);
}
.createschoolaccountinfo__container > h2,
.buttonLoginSchool > h2 {
  font-size: 20px;
  font-weight: 700;
  color: red;
  text-align: center;
}
.createschoolaccountinfo__container > p {
  font-size: 18px;
  font-weight: 400;
  color: gray;
  margin-left: 20px;
  margin-bottom: 10px;
}
.createschoolaccountinfo__container > hr,
.InsideHr > hr {
  width: 850px !important;
  border: 1px solid var(--connected-color);
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.createschoolaccountschooldetails__nameadminaddressAddress {
  display: flex;
  flex-direction: row;
}
.createschoolaccountinfo__containerVMDataVision,
.createschoolaccountinfo__containerVMDataMission,
.createschoolaccountinfo__containerVMDataAbout {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.createschoolaccountinfo__containerVMDataVision > h4,
.createschoolaccountinfo__containerVMDataMission > h4,
.createschoolaccountinfo__containerVMDataAbout > h4 {
  font-size: 18px;
  font-weight: 700;
  width: 100px;
  text-align: center;
  margin-top: 14px;
}
.createschoolaccountinfo__containerVMDataEstablished {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 100px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.createschoolaccountinfo__containerVMDataEstablished > h4 {
  font-size: 18px;
  font-weight: 700;
  width: 150px;
}
.createschoolaccountinfo__containerFacilities {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
}
.createschoolaccountinfo__containerFacilities > h5 {
  font-size: 14px;
  font-weight: 600;
  color: red;
  margin-bottom: 20px;
  width: 800px;
}
.createschoolaccountinfo__containerFacilities > p {
  font-size: 14px;
  font-weight: 700;
  color: gray;
  margin-bottom: 20px;
}
.createschoolaccountinfo__containerFacilitiesData {
  display: flex;
  flex-direction: row;
}
.createschoolaccountinfo__containerFacilitiesData > h4 {
  font-size: 18px;
  font-weight: 700;
  color: var(--connected-color);
  width: 200px;
  text-align: center;
  justify-content: center;
  /* margin-top: 10px; */
  margin-right: 30px;
}
.createschoolaccountinfo__containerFacilitiesDataRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}
.createschoolaccountinfo__containerFacilitiesDataRow > input {
  margin-left: 10px;
  margin-right: 10px;
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.createschoolaccountinfo__containerFacilitiesDataRow > label {
  font-size: 16px;
  font-weight: 600;
  margin-left: 5px;
  cursor: pointer;
}
.buttonLoginSchool > button,
.createschoolaccountinfo__containerOne > button {
  color: white;
  background-color: var(--connected-color);
  cursor: pointer;
  border: 1px solid var(--connected-color);
  font-size: 13px;
  font-weight: 700;
  text-transform: inherit;
  border-radius: 4px;
  width: 150px;
  height: 35px;
  font-size: 18px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 10px;
}
.buttonLoginSchool > button:hover,
.createschoolaccountinfo__containerOne > button:hover {
  color: var(--connected-color);
  background-color: white;
  border: 1px solid var(--connected-color);
}
.addinfo > h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 30px;
}
.createschoolaccountinfo__containerOne,
.InsideHr,
.buttonLoginSchool {
  display: flex;
  flex-direction: column;
  align-items: center;
}
