.homeworkstudents {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
}
.homeworkstudents__top > hr {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  border: 1px solid lightgray;
  /* border: 1px solid var(--connected-color); */
}
.homeworkstudents__top {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.homeworkstudents__top > h3 {
  font-size: 22px;
  font-weight: 700;
  color: var(--connected-color);
}
.homeworkstudents__top > h4 {
  margin-top: 15px;
  /* margin-bottom: 20px; */
  font-size: 20px;
}
.studentsName {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.studentsName > h3 {
  font-size: 18px;
  font-weight: 500;
  width: 130px;
  color: gray;
}
.studentsName > h4 {
  font-size: 18px;
  font-weight: 500;
  width: 20px;
}
.studentsNameName {
  max-width: 400px;
}
.studentsNameName > h5 {
  font-size: 18px;
  font-weight: 700;
}
.studentsNameName > p {
  font-size: 14px;
  font-weight: 500;
}
.homeworkstudents__bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.homeworkStudentNotVerified > h3 {
  font-size: 18px;
  font-weight: 700;
  color: var(--connected-orange);
  width: 800px;
  text-align: center;
}
