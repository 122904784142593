.leaveOption {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.leaveOption>h3 {
    font-size: 17px;
    font-weight: 700;
    margin-right: 20px;
}

.leaveDate {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.oneDay,
.oneDayTeacher {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 750px;
    border: 1px solid var(--connected-color);
    border-radius: 5px;
    padding: 5px;
    margin-top: 10px;
}

.oneDay>h3 {
    font-size: 18px;
    font-weight: 700;
    margin-top: 10px;
    color: var(--connected-orange);
}

.leavedatedata {
    margin: 20px;
}

.leaveDate>p {
    font-size: 16px;
    font-weight: 700;
    margin-right: 30px;
    color: gray;
}

.leavedatedata>p {
    font-size: 14px;
    font-weight: 400;
    color: red;
    text-align: center;
}

.leaveDate>input {
    width: 150px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid lightgray;
}

.oneDay>button,
.oneDayTeacher>button,
.leaveButtons>button {
    color: white;
    background-color: var(--connected-color);
    cursor: pointer;
    border: 1px solid var(--connected-color);
    font-size: 13px;
    font-weight: 700;
    text-transform: inherit;
    border-radius: 4px;
    width: 200px;
    height: 35px;
    margin: 10px;
    font-size: 16px;
    font-weight: 600;
}

.oneDay>h5,
.oneDayTeacher>h5 {
    font-size: 18px;
    font-weight: 700;
    color: green;
    margin-bottom: 20px;
}

.oneDay>button:hover,
.oneDayTeacher>button:hover,
.leaveButtons>button:hover {
    color: var(--connected-color);
    background-color: white;
    border: 1px solid var(--connected-color);
}

.teacherLeave>h3 {
    text-align: center;
    margin: 10px 0 30px 0;
    color: gray;
}

.leaveDataAcco {
    margin-left: auto;
    margin-right: auto;
}

.leaveData {
    display: flex;
    flex-direction: row;
    width: 600px;
    margin: 10px 0;
}

.leaveData>p {
    font-size: 16px;
    font-weight: 700;
    width: 100px;
    text-align: right;
    padding-right: 20px;

}

.leaveData>h5 {
    font-size: 16px;
    font-weight: 600;
    /* color: gray; */
    width: 450px;
}

.princiSubRowLeave {
    width: 850px;
}

.princiSubRowLeave>.MuiAccordion-root>.MuiAccordionSummary-root {
    background-color: rgb(250, 239, 239);
    padding-left: 50px;
    margin-bottom: 10px;
}

.princiSubRowLeave>.MuiAccordion-root>.MuiAccordionSummary-root:hover {
    background-color: rgb(247, 224, 190);
}

.sendApplication>.MuiAccordion-root>.MuiAccordionSummary-root {
    background-color: rgb(250, 231, 231);
}

.sendApplication>.MuiAccordion-root>.MuiAccordionSummary-root:hover {
    background-color: rgb(247, 224, 190);
}

.headingLeave>h3 {
    font-size: 18px;
    font-weight: 600;
    color: var(--connected-orange);
}

.sentApplications>h3 {
    text-align: left;
    font-size: 18px;
    font-weight: 700;
    color: var(--connected-orange);
    margin: 20px;
}

.leaveAppData {
    display: flex;
    flex-direction: row;

}

.leaveAppData>h3 {
    font-size: 16px;
    font-weight: 600;
    color: var(--connected-color);
    width: 200px;
    text-align: right;
    margin-right: 10px;
}

.leaveAppData>p {
    font-size: 16px;
    font-weight: 700;
    width: 500px;
    /* color: var(--connected-orange); */
}

.oneSentApplication>.MuiAccordion-root {
    width: 760px;
}

.oneSentApplication>.MuiAccordion-root>.MuiAccordionSummary-root {
    background-color: rgb(222, 222, 245);
}

.oneSentApplication>.MuiAccordion-root>.MuiAccordionSummary-root:hover {
    background-color: rgb(185, 185, 245);
}

.leaveAppData {
    margin: 10px;
}

.leaveAppDataDiv {
    padding: 20px;
    border: 1px solid var(--connected-color);
    border-radius: 4px;
}

.oneAppData {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sentApplications>h3 {
    margin-top: 50px;
    color: var(--connected-color);
}

.leaveDataHead {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    margin-right: 20px;
}

.leaveDataHead>h3 {
    width: 80px;
    font-size: 16px;
    font-weight: 700;
}

.leaveDataHead>p {
    font-size: 16px;
    font-weight: 700;
    height: 25px;
    overflow: hidden;
    max-width: 400px;
    color: gray;
}

.oneSentApplication {
    margin-bottom: 10px;
}