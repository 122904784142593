/* PARENTS?LOCAL-GUARDIANS */
.createnewaccountinfo__containerParents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.createnewaccountinfo__containerParents > h3 {
  font-size: 22px;
  font-weight: 700;
  color: var(--connected-color);
  text-align: center;
  margin-top: 20px;
}
.createnewaccountinfo__containerParents > h4 {
  font-size: 14px;
  font-weight: 500;
  color: gray;
  text-align: center;
  margin-top: 20px;
}
.createnewaccountinfo__containerParents > h5 {
  font-size: 14px;
  font-weight: 500;
  color: gray;
  text-align: center;
}
.h5__1ChildMedium > h5,
.h5__1ChildFName > h5,
.h5__1ChildLName > h5,
.h5__1ChildSChoolName > h5,
.h5__1ChildClass > h5,
.h5__1ChildSection > h5,
.h5__2ChildMedium > h5,
.h5__2ChildFName > h5,
.h5__2ChildLName > h5,
.h5__2ChildSChoolName > h5,
.h5__2ChildClass > h5,
.h5__2ChildSection > h5,
.h5__3ChildMedium > h5,
.h5__3ChildFName > h5,
.h5__3ChildLName > h5,
.h5__3ChildSChoolName > h5,
.h5__3ChildClass > h5,
.h5__3ChildSection > h5,
.h5__4ChildMedium > h5,
.h5__4ChildFName > h5,
.h5__4ChildLName > h5,
.h5__4ChildSChoolName > h5,
.h5__4ChildClass > h5,
.h5__4ChildSection > h5,
.h5__5ChildMedium > h5,
.h5__5ChildFName > h5,
.h5__5ChildLName > h5,
.h5__5ChildSChoolName > h5,
.h5__5ChildClass > h5,
.h5__5ChildSection > h5 {
  font-size: 13px;
  font-weight: 600;
  color: red;
  margin-left: 20px;
  text-align: justify;
  margin-bottom: 3px;
}
.h5__1ChildMedium,
.h5__2ChildMedium,
.h5__3ChildMedium,
.h5__4ChildMedium,
.h5__5ChildMedium {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.h5__1ChildMedium > h5,
.h5__2ChildMedium > h5,
.h5__3ChildMedium > h5,
.h5__4ChildMedium > h5 .h5__5ChildMedium > h5 {
  margin-right: 40px;
}
.h5__1ChildSChoolName > h5,
.h5__2ChildSChoolName > h5,
.h5__3ChildSChoolName > h5,
.h5__4ChildSChoolName > h5,
.h5__5ChildSChoolName > h5 {
  width: 550px;
}

.ParentsProfilePic {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
}
.ParentsProfilePicleft > h2 {
  font-size: 20px;
  font-weight: 700;
  color: var(--connected-color);
  margin-right: 100px;
}
.ParentsProfilePicRight {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ParentsProfilePicRight > button {
  color: white;
  background-color: gray;
  cursor: pointer;
  border: 1px solid gray;
  font-size: 13px;
  font-weight: 700;
  border-radius: 4px;
  text-transform: inherit;
  width: 120px;
  height: 25px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
}
.ParentsProfilePicRight > button:hover {
  color: gray;
  background-color: white;
}
#child1,
#child2,
#child3,
#child4,
#child5 {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
#child1 > button #child2 > button,
#child3 > button,
#child4 > button,
#child5 > button {
  font-size: 13px;
  color: var(--connected-orange);
  background-color: white;
  border: none;
  outline: none;
  font-weight: 800;
  text-align: end;
  margin-right: 40px;
  cursor: pointer;
}
#child1 > button:hover,
#child2 > button:hover,
#child3 > button:hover,
#child4 > button:hover,
#child5 > button:hover {
  cursor: pointer;
  text-decoration: underline;
}
#child1 > h3,
#child2 > h3,
#child3 > h3,
#child4 > h3,
#child5 > h3 {
  color: var(--connected-color);
  font-size: 16px;
  font-weight: 700;
  text-align: end;
  margin-right: 40px;
}
.afterClick1,
.afterClick2,
.afterClick3,
.afterClick4,
.afterClick5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.afterClick1 > h5,
.afterClick2 > h5,
.afterClick3 > h5,
.afterClick4 > h5,
.afterClick5 > h5 {
  color: var(--connected-color);
  font-size: 16px;
  font-weight: 700;
  text-align: end;
  margin-right: 40px;
}
.afterClick1 > button,
.afterClick2 > button,
.afterClick3 > button,
.afterClick4 > button,
.afterClick5 > button {
  font-size: 13px;
  color: var(--connected-orange);
  background-color: white;
  border: none;
  outline: none;
  font-weight: 800;
  margin-right: 40px;
  cursor: pointer;
}
.afterClick1 > button:hover,
.afterClick2 > button:hover,
.afterClick3 > button:hover,
.afterClick4 > button:hover,
.afterClick5 > button:hover {
  cursor: pointer;
  text-decoration: underline;
}
.createnewaccountinfo__containerParents > p:hover {
  cursor: pointer;
  text-decoration: underline;
}
.childOne {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 20px 30px;
}
.childsname {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.ChildsClassnSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.ChildsClassSection {
  margin-left: 20px;
}
.createnewaccountinfo__containerParentsChildsMedium {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}
.createnewaccountinfo__containerParentsChildsMedium > h3 {
  font-size: 18px;
  font-weight: 700;
  margin-right: 20px;
}
.childsMedium {
  display: flex;
  flex-direction: row;
}
.ChildsEnglishMedium,
.ChildsMarathiMedium {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-bottom: 10px; */
  cursor: pointer;
}
.ChildsEnglishMedium > input,
.ChildsMarathiMedium > input {
  cursor: pointer;
}
.ChildsEnglishMedium {
  margin-top: 3px;
}
.ChildsEnglishMedium > label,
.ChildsMarathiMedium > label {
  font-size: 16px;
  font-weight: 700;
  margin-left: 10px;
  color: gray;
  width: 150px;
  cursor: pointer;
}
.childOne > button {
  color: white;
  background-color: var(--connected-color);
  cursor: pointer;
  border: 1px solid var(--connected-color);
  text-transform: inherit;
  border-radius: 4px;
  width: 400px;
  height: 35px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 700;
  margin-left: auto;
  margin-right: auto;
}
.childOne > button:hover {
  color: var(--connected-color);
  background-color: white;
  border: 1px solid var(--connected-color);
}
.childOne > h5 {
  color: var(--connected-orange);
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
.afterClick1 > h4 {
  font-size: 20px;
  font-weight: 700;
  color: red;
}
.goToProfileButton > button {
  color: white;
  background-color: var(--connected-color);
  cursor: pointer;
  border: 1px solid var(--connected-color);
  text-transform: inherit;
  border-radius: 4px;
  width: 200px;
  height: 35px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 700;
  margin-left: auto;
  margin-right: auto;
}
.goToProfileButton > button:hover {
  color: var(--connected-color);
  background-color: white;
  border: 1px solid var(--connected-color);
}
.parents__radioMediumMuiDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.parents__radioMediumMui {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
}
.class_section {
  display: flex;
  flex-direction: row;
  align-items: center;
}
/* PARENTS?LOCAL-GUARDIANS */
