.homeworkteachers {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
}
.homeworkteachers__top > hr {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid lightgray;
}
.homeworkteachers__top {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.homeworkteachers__top > h3 {
  font-size: 22px;
  font-weight: 700;
  color: var(--connected-color);
}
.homeworkteachers__top > h4 {
  margin-top: 15px;
  /* margin-bottom: 20px; */
  font-size: 20px;
}
