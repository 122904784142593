.createschoolaccountschooldetails,
.schoolDetailsContainerParent {
  height: 100%;
  display: grid;
  background-color: var(--connected-background);
  place-items: center;
}

.createschoolaccount__containerinDetails,
/* .schoolDetailsContainer, */
.schoolDetailsContainerHeading {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 50px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.createschoolaccount__containerinDetails>h1,
.schoolDetailsContainerHeading>h1 {
  font-size: 50px;
  font-weight: 700;
  color: var(--connected-color);
}

.createschoolaccount__containerinDetails>p,
.schoolDetailsContainerHeading>p {
  font-size: 18px;
  font-weight: 400;
  color: gray;
  margin-left: 20px;
  margin-bottom: 10px;
}

.schoolDetailsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h4 {
  font-size: 18px;
  font-weight: 700;
  color: var(--connected-color);
}

.createschoolaccount__containerinDetails>hr,
.schoolDetailsContainerHeading>hr {
  width: 850px !important;
  border: 1px solid var(--connected-color);
  margin-bottom: 20px;
  margin-top: 20px;
}

.createschoolaccountschooldetails__nameadminaddress>hr {
  width: 850px !important;
  border: 1px solid var(--connected-color);
  margin-bottom: 20px;
  margin-top: 20px;
}

label {
  font-size: 16px;
  font-weight: 700;
  color: var(--connected-color);
}

.createschoolaccountschooldetails__nameadminaddress {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.createschoolaccountschooldetails__nameadminaddress>p {
  font-size: 14px;
  font-weight: 600;
  width: 600px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.createschoolaccountschooldetails__nameadminaddress>p>strong {
  color: black;
}

.adminName,
.createschoolaccountschooldetails__nameadminaddressAddressDeatilsCityVillageTalluka,
.createschoolaccountschooldetails__nameadminaddressAddressDeatilsCity {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.example {
  color: red;
  text-align: center;
  margin-top: 3px;
}

.createschoolaccountschooldetails__nameadminaddress>button {
  color: white;
  background-color: var(--connected-color);
  cursor: pointer;
  border: 1px solid var(--connected-color);
  font-size: 13px;
  font-weight: 700;
  text-transform: inherit;
  border-radius: 4px;
  width: 200px;
  height: 35px;
  margin-top: 30px;
  font-size: 18px;
  font-weight: 700;
}

.createschoolaccountschooldetails__nameadminaddress>button:hover {
  color: var(--connected-color);
  background-color: white;
  border: 1px solid var(--connected-color);
}

.createschoolaccountschooldetails__nameadminaddress>input,
.createschoolaccountschooldetails__nameadminaddressAddress>input,
.createschoolaccountschooldetails__nameadminaddressAddressDeatilsCity>input,
.createschoolaccountschooldetails__nameadminaddressAddressDeatilsPinStates>input {
  background-color: white;
  border: 1px solid gray;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 500;
}

.createschoolaccountschooldetails__nameadminaddressAddressDeatilsPinStates {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.createschoolaccountschooldetails__nameadminaddressAddressDeatilsAffiliation {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
}

/* //////////////////////////////////////////////////////////////////////////////////////////// */