.homeworksteachersclassnsubject {
  display: flex;
  flex-direction: row;
  /* margin-top: 20px; */
}
.homeworksteachersclassnsubject__left {
  width: 300px;
}
.teacherClassTab {
  padding: 4px 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  border-left: 4px solid white;
}
.teacherClassTab > h4 {
  font-size: 12px;
  font-weight: 500;
  color: gray;
}
.teacherClassTab > h3 {
  font-size: 12px;
  font-weight: 700;
  color: black;
}
.teacherClassTab > h2 {
  font-size: 20px;
  font-weight: 700;
  color: var(--connected-orange);
}
.teacherClassTab:hover {
  cursor: pointer;
  background-color: rgb(248, 238, 221);
  border-left: 4px solid var(--connected-color);
}
.teacherNotVerified > h2 {
  font-size: 18px;
  font-weight: 500;
  color: var(--connected-orange);
  width: 600px;
  margin-top: 40px;
}

.homeworksteachersclassnsubject__right {
  width: 700px;
}
.homeworksteachersclassnsubject__right > h3 {
  color: var(--connected-color);
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
.homeworksteachersclassnsubject__right > hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid var(--connected-color);
  width: 100% !important;
}
.headingHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.headingHeading > h4 {
  font-size: 16px;
  font-weight: 700;
  color: var(--connected-orange);
}
.headingHeading > hr {
  width: 650px !important;
  margin-bottom: 10px;
  border: 1px solid lightgray;
}
.headingHeadingSubject {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 20px;
}
.headingHeadingSubject > h2 {
  font-size: 20px;
  font-weight: 700;
  width: 80px;
  color: gray;
}
.headingHeadingSubject > h3 {
  font-size: 20px;
  font-weight: 700;
  width: 20px;
}
.headingHeadingSubject > h4 {
  font-size: 20px;
  font-weight: 700;
}
.noHomeworkProject {
  width: 600px;
}
.noHomeworkProject > h2 {
  font-size: 18px;
  font-weight: 700;
  color: var(--connected-orange);
  margin-left: 20px;
  margin-top: 20px;
}
.teacherhomewrokaccordianTop {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.teacherhomewrokaccordianTop_Head {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.teacherhomewrokaccordianTop_Head > h4 {
  font-size: 12px;
  font-weight: 600;
  color: gray;
}
.teacherhomewrokaccordianTop_Head > h3 {
  font-size: 12px;
  font-weight: 700;
  color: black;
}
#HomeWorksTeachers > .MuiAccordion-root > .MuiAccordionSummary-root {
  background-color: rgb(243, 242, 242);
}
#HomeWorksTeachers > .MuiAccordion-root > .MuiAccordionSummary-root:hover {
  background-color: rgb(216, 215, 215);
}
.UploadedOn > h4 {
  width: 85px;
}
.SubmitOn > h3,
.UploadedOn > h3 {
  width: 195px;
}
.SubmitOn > h4 {
  width: 110px;
}
.teacherhomewrokaccordianTop > button {
  background-color: var(--connected-color);
  border: none;
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-transform: inherit;
  border-radius: 3px;
  height: 25px;
  width: 60px;
}
.teacherhomewrokaccordianTop > button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}
.recentlyAdddedHW {
  display: flex;
  flex-direction: column;
  /* align-items: ; */
}
.recentlyAdddedData {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.recentlyAdddedData > h3 {
  font-size: 18px;
  font-weight: 700;
  margin-left: 40px;
  margin-top: 10px;
}
.recentlyAdddedData > h4 {
  font-size: 14px;
  font-weight: 500;
  margin-left: 40px;
  color: red;
  margin-bottom: 10px;
  width: 650px;
}
.recentlyAdddedHead > h2 {
  font-size: 18px;
  font-weight: 700;
  color: var(--connected-color);
}
.addnewHw {
  margin-top: 30px;
}
.addnewHw > h2 {
  margin-bottom: 10px;
}
.spanQuestion {
  color: var(--connected-color);
}
.publishHomeWrokHere > h3 {
  font-size: 16px;
  font-weight: 700;
  color: var(--connected-orange);
}
.addnewHw__Details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.publishedSuccess > h3 {
  font-size: 16px;
  font-weight: 700;
  color: green;
  margin-top: 5px;
}
.addnewHw__DetailsSubmit {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.addnewHw__DetailsSubmit > h5 {
  font-size: 14px;
  font-weight: 500;
  color: red;
  margin-bottom: 10px;
}
.addnewHw__DetailsSubmitOn {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.addnewHw__DetailsSubmitOn > .DatePicker {
  width: 180px;
  height: 40px;
}
.addnewHw__DetailsSubmitOn > h5 {
  font-size: 18px;
  font-weight: 700;
  color: var(--connected-color);
  margin-right: 20px;
}
.buttonPublishCancel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.buttonPublishCancel > button {
  background-color: var(--connected-color);
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-transform: inherit;
  border-radius: 3px;
  height: 35px;
  width: 80px;
  margin-left: 10px;
  margin-right: 10px;
}
.buttonPublishCancel > button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}

/* HOMEWORK, ASSIGNEMENT, MINI PROJECT, PROJECT */
.homeworksteachersclassnsubject__rightDataHomework {
  border: 1px solid lightgray;
  border-radius: 20px;
  width: 620px;
  padding: 20px;
}
.homeworksteachersclassnsubject__rightDataHomeworkQue > h4 {
  font-size: 16px;
  font-weight: 700;
  color: black;
  margin-bottom: 10px;
}
.homeworksteachersclassnsubject__rightDataHomeworkImage > center > img {
  max-width: 400px;
  max-height: 300px;
  border-radius: 4px;
  margin-left: 100px;
  margin-bottom: 10px;
  cursor: pointer;
}
.homeworksteachersclassnsubject__rightDataHomework > h5,
.homeworksteachersclassnsubject__rightDataHomework > p {
  font-size: 14px;
  font-weight: 600;
  color: gray;
  margin-left: 40px;
}
#addNewHWPA > .MuiAccordion-root > .MuiAccordionSummary-root {
  background-color: rgb(248, 235, 235);
}
#addNewHWPA > .MuiAccordion-root > .MuiAccordionSummary-root:hover {
  background-color: rgb(247, 219, 219);
}
.homeworkImage {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.homeworkImage > img {
  max-width: 350px;
  max-height: 350px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.homeworkImage > h4 {
  font-size: 18px;
  font-weight: 700;
  /* color: gray; */
  margin-bottom: 10px;
}
/* HOMEWORK, ASSIGNEMENT, MINI PROJECT, PROJECT */
