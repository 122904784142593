.AllocateOneTeacher {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}

.AllocateOneTeacher>p {
    font-size: 18px;
    font-weight: 700;
    color: var(--connected-orange);
    width: 470px;
}

.AllocateOneTeacher>h3 {
    font-size: 18px;
    font-weight: 700;
    color: var(--connected-color);
    min-width: 200px;
    width: 200px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    overflow: hidden;
    margin: 10px 0;
}

.AllocateOneTeacher>h5 {
    margin-left: 20px;
    width: 107px;
    text-align: center;

}

.AllocateOneTeacher>h5:hover {
    cursor: pointer;
    text-decoration: underline;
}

.AllocateOneTeacher>button {
    margin-left: 20px;
}

.allTeachersSelections>h3 {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin: 20px;
    margin-bottom: 40px;
    color: var(--connected-color);
}