.userprofiletop {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  margin-top: 5px;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 4px;
}
.userprofiletop__info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#avtarNameProfileTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
#avtarNameProfileTop > .MuiAvatar-root {
  cursor: pointer;
}
.profileImageBig > img {
  max-width: 700px;
  max-height: 700px;
  object-fit: contain;
}
#avtarNameProfileName {
  display: flex;
  flex-direction: column;
  width: 600px;
  /* background-color: red; */
}
#avtarNameProfileName > h5 {
  font-size: 30px;
  font-weight: 700;
  margin-left: 30px;
}
.userprofilenavbar__rightButton {
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.userprofilenavbar__rightButton > button,
.userprofilenavbar__rightButtonConnect > button {
  background-color: var(--connected-color);
  border: 1px solid var(--connected-color);
  color: white;
  font-weight: 760;
  text-transform: inherit;
  border-radius: 2px;
  width: 100px;
  /* padding: 10px; */
  height: 30px;
  margin-top: 5px;
  margin-right: 5px;
}
.userprofilenavbar__rightButton > button:hover,
.userprofilenavbar__rightButtonConnect > button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}
.userprofilenavbar__rightButtonRemove > button {
  background-color: var(--connected-orange);
  border: 1px solid var(--connected-orange);
  color: white;
  font-weight: 760;
  text-transform: inherit;
  border-radius: 2px;
  width: 200px;
  /* padding: 10px; */
  height: 30px;
  margin-top: 5px;
  margin-right: 5px;
}
.userprofilenavbar__rightButtonRemove > button:hover {
  background-color: white;
  color: var(--connected-orange);
  border: 1px solid var(--connected-orange);
}
.userprofilenavbar__rightButtonAccept > button {
  background-color: green;
  border: 1px solid green;
  color: white;
  font-weight: 760;
  text-transform: inherit;
  border-radius: 2px;
  width: 100px;
  height: 30px;
  margin-top: 5px;
  margin-right: 5px;
}
.userprofilenavbar__rightButtonAccept > button:hover {
  background-color: white;
  color: green;
  border: 1px solid green;
}
.userprofilenavbar__rightButtonReject > button {
  background-color: var(--connected-orange);
  border: 1px solid var(--connected-orange);
  color: white;
  font-weight: 760;
  text-transform: inherit;
  border-radius: 2px;
  width: 100px;
  height: 30px;
  margin-top: 5px;
  margin-right: 5px;
}
.userprofilenavbar__rightButtonReject > button:hover {
  background-color: white;
  color: var(--connected-orange);
  border: 1px solid var(--connected-orange);
}
.userprofilenavbar__right > .MuiDialog-root {
  width: 700px;
}
.dialogEditProfile {
  width: 580px;
  min-height: 300px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dialogEditProfile > h2 {
  /* color: orange; */
  margin-top: 30px;
}
.dialogEditProfileBio,
.dialogEditProfileMobNo,
.dialogEditProfileEmail,
.editBirthday,
.editgender {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dialogEditProfileMobNo > .MuiTextField-root,
.dialogEditProfileBio > .MuiTextField-root,
.dialogEditProfileEmail > .MuiTextField-root {
  width: 500px;
  margin-bottom: 5px;
  margin-top: 30px;
}
.editDisplayPic {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.editDisplayPic > .MuiAvatar-root {
  margin-bottom: 20px;
}
.editBirthdayInput {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 30px;
}
.editBirthdayInput > h5 {
  color: var(--connected-orange);
  font-size: 20px;
  font-weight: 700;
  margin-right: 10px;
}
.editBirthdayInput > input {
  border: 1px solid lightgray;
  font-size: 16px;
  color: gray;
  font-weight: 700;
  border-radius: 3px;
  width: 200px;
  height: 40px;
  cursor: pointer;
  padding-left: 20px;
  /* margin-bottom: 20px; */
}
.createnewaccount__gendersdiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}
.editgenderOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}
.editgenderFields > label {
  font-size: 15px;
  cursor: pointer;
  font-weight: 500;
  margin-left: 3px;
  /* color: gray; */
}
.editgenderFields > input {
  margin-left: 20px;
  cursor: pointer;
}
.editButtons {
  margin: 20px;
}
.editQualificationMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.editQualification {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}
.editQualificationDegree > .MuiFormControl-root {
  width: 130px;
}
.editQualificationSpecialization > .MuiTextField-root,
.editQualificationUniversity > .MuiTextField-root {
  width: 150px;
  margin-left: 5px;
}
.editQualificationPassout > .MuiFormControl-root {
  width: 90px;
  margin-left: 5px;
}
.editAchievements {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.editAchievements > .MuiTextField-root {
  margin-bottom: 5px;
  width: 500px;
}
.editAwardsAddOrRemove {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.editAwardsAddOrRemove > h2 {
  font-size: 16px;
  font-weight: 700;
  color: orange;
  margin-bottom: 10px;
  text-align: right;
  margin-right: 20px;
}
.editAwardsAddOrRemove > h2:hover {
  text-decoration: underline;
  cursor: pointer;
}
.editAwardsAddOrRemove > h3 {
  font-size: 16px;
  font-weight: 700;
  color: orange;
  margin-bottom: 10px;
  text-align: right;
}
.editAwardsAddOrRemove > h3:hover {
  text-decoration: underline;
  cursor: pointer;
}
.editUpdateButton > button, .editDisplayPic>button {
  font-size: 15px;
  font-weight: 700;
  height: 30px;
  text-decoration: inherit;
  /* margin-bottom: 20px; */
  background-color: orange;
  border: 1px solid orange;
  color: white;
  text-transform: inherit;
}
.editUpdateButton > button:hover, .editDisplayPic>button:hover {
  background-color: white;
  border: 1px solid orange;
  color: orange;
}
.imageButtons > button {
  /* margin-left: 10px; */
}
.editDisplayPic > h2,
.dialogEditProfileBio > h2,
.dialogEditProfileMobNo > h2,
.dialogEditProfileEmail > h2,
.editBirthday > h2,
.editgender > h2,
.editAchievements > h2,
.editQualificationMain > h2,
.dialogEditUserName > h2 {
  font-size: 16px;
  font-weight: 700;
  color: green;
  margin-bottom: 20px;
}
.dialogEditUserName {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.dialogEditUserNameBox {
  margin-bottom: 5px;
}
.sendMsgBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  padding: 20px;
  /* max-height: 200px; */
}
.sendMsgBox > .MuiTextField-root {
  width: 500px;
}
.sendMsgBox > button {
  background-color: #2e81f4;
  /* border: 1px solid #2e81f4; */
  color: white;
  font-size: 16px;
  font-weight: 700;
  text-transform: inherit;
  border-radius: 2px;
  width: 300px;
  height: 35px;
  margin-top: 5px;
  margin-right: 5px;
}
.sendMsgBox > button:hover {
  background-color: white;
  color: #2e81f4;
  border: 1px solid #2e81f4;
}
.sendMsgBox > h3 {
  font-size: 18px;
  font-weight: 700;
  color: green;
}
