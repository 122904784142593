/* STUDENTS */
.SchoolVerifiedMembersStudents__containerRightStudentsData {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.SchoolVerifiedMembersStudents__containerRightStudentsData {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  /* margin-left: 20px; */
}
.SchoolVerifiedMembersStudents__containerRightStudentsDataName {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.SchoolVerifiedMembersStudents__containerRightStudentsDataName > h3 {
  font-size: 16px;
  font-weight: 700;
  width: 350px;
  margin-left: 10px;
  margin-right: 10px;
}
.SchoolVerifiedMembersStudents__containerRightStudentsDataButtons {
  display: flex;
  flex-direction: row;
}
.SchoolVerifiedMembersStudents__containerRightStudentsDataButtons > button {
  background-color: var(--connected-color);
  border: none;
  color: white;
  font-weight: 700;
  text-transform: inherit;
  border-radius: 3px;
  width: 70px;
  height: 30px;
  margin-left: 10px;
}
.SchoolVerifiedMembersStudents__containerRightStudentsDataButtons
  > button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}
/* STUDENTS */
