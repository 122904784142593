.headermessages {
  display: flex;
  flex-direction: row;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  padding: 5px;
}
.headermessages__leftavatar {
  cursor: pointer;
}
.headermessages__right {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  /* cursor: pointer; */
}
.headermessages__right > h5 {
  font-size: 12px;
  font-weight: 600;
  /* margin-bottom: 3px; */
  cursor: pointer;
}
.headermessages__right > h5:hover {
  text-decoration: underline;
}
.headermessages__right > p {
  font-size: 14px;
  font-weight: 400;
  max-height: 37px;
  overflow: hidden;
  margin-top: 5px;
}
.isNotViewed {
  background-color: rgb(236, 234, 234);
  border-radius: 10px;
}
