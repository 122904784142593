.FindToFollowTeachersNFriends {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.FindToFollowTeachersNFriendsRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.FindToFollowTeachersNFriends > h2 {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-left: 10px;
}
.FindToFollowTeachersNFriendsRow > .MuiCard-root {
  margin-left: 10px;
  border: 1px solid lightgray;
  height: 230px;
}
.FindToFollowTeachersNFriendsCard,
.FriendsCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 5px;
}
.FindToFollowTeachersNFriendsCard > p,
.FriendsCard > p {
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  height: 50px;
  /* background-color: red; */
}
.FindToFollowTeachersNFriendsCard > h5,
.FriendsCard > h5 {
  font-size: 12px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 5px;
  color: gray;
}
.FindToFollowTeachersNFriendsCard > p:hover,
.FriendsCard > p:hover,
.FindToFollowTeachersNFriends > h2:hover {
  text-decoration: underline;
  cursor: pointer;
}
.FindToFollowTeachersNFriendsCardButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}
.FindToFollowTeachersNFriendsCardButtonsConnect > button,
.FindToFollowTeachersNFriendsCardButtonsFollow > button {
  background-color: #2e81f4;
  border: none;
  color: white;
  font-size: 13px;
  font-weight: 600;
  text-transform: inherit;
  border-radius: 3px;
  height: 25px;
  width: 80px;
  margin-bottom: 5px;
}
.FindToFollowTeachersNFriendsCardButtonsConnect > button:hover,
.FindToFollowTeachersNFriendsCardButtonsFollow > button:hover {
  background-color: white;
  color: #2e81f4;
  border: 1px solid #2e81f4;
}
.FindToFollowTeachersNFriendsCardButtonsH3 > h3 {
  font-size: 13px;
  font-weight: 600;
  margin-top: 2px;
  color: rgb(241, 162, 15);
}
.FindToFollowTeachersNFriendsCardButtonsH3 > h3:hover {
  text-decoration: underline;
  cursor: pointer;
}
.FollowingTeachersNFriendsCardButtons > button {
  background-color: rgb(241, 162, 15);
  border: none;
  color: white;
  font-size: 13px;
  font-weight: 600;
  text-transform: inherit;
  border-radius: 3px;
  height: 25px;
  width: 80px;
  margin-bottom: 5px;
  margin-top: 15px;
}
.FollowingTeachersNFriendsCardButtons > button:hover {
  background-color: white;
  color: rgb(241, 162, 15);
  border: 1px solid rgb(241, 162, 15);
}
.FindToFollowTeachersNFriendsCardAcceptButtons > button {
  background-color: green;
  border: none;
  color: white;
  font-size: 13px;
  font-weight: 600;
  text-transform: inherit;
  border-radius: 3px;
  height: 25px;
  width: 80px;
  margin-bottom: 5px;
  /* margin-top: 15px; */
}
.FindToFollowTeachersNFriendsCardAcceptButtons > button:hover {
  background-color: white;
  color: green;
  border: 1px solid green;
}
.FriendsCardBlock {
  margin-bottom: 5px;
  border: 1px solid lightgray;
}
.userprofilenavbar__rightButtonRemoveCard > button {
  background-color: rgb(241, 162, 15);
  border: none;
  color: white;
  font-size: 13px;
  font-weight: 600;
  text-transform: inherit;
  border-radius: 3px;
  height: 25px;
  width: 80px;
  margin-bottom: 5px;
  margin-top: 15px;
}
.userprofilenavbar__rightButtonRemoveCard > button:hover {
  background-color: white;
  color: rgb(241, 162, 15);
  border: 1px solid rgb(241, 162, 15);
}
.youCard > h4 {
  font-size: 16px;
  font-weight: 600;
  color: green;
  margin-top: 30px;
}
.friendsAllFollow {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
  padding: 10px;
}
