.sidebar {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 4px;
  position: sticky;
  top: 70px;
  z-index: 1;
}

.sidebar__name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.sidebar__name>h5 {
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
}

.sidebar__name>.MuiAvatar-root:hover {
  cursor: pointer;
}

.sidebar__name>h5:hover {
  cursor: pointer;
  text-decoration: underline;
}

.sidebar__info {
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 0;
}

.sidebar__school {
  padding: 0 10px 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

}

.sidebar__topstudent {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}

.sidebar__at {
  padding: 5px 0;
  margin-left: auto;
  margin-right: auto;
  font-size: 13px;
  font-weight: 900;
  margin-top: 10px;
}

.sidebar__student {
  font-size: 14px;
  padding-left: 15px;
  padding-right: 10px;
}



.sidebar__school>h5 {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 700;
}

.sidebar__school>.MuiAvatar-root:hover {
  cursor: pointer;
}

.sidebar__school>h5:hover {
  cursor: pointer;
  text-decoration: underline;
}

.sidebar__infoStatus {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 10px;
}

.sidebar__infoStatus>p {
  font-size: 13px;
  font-weight: 500;
  color: gray;
  margin-right: 50px;
}

.userCurrentStatus {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userCurrentStatusChange {
  display: flex;
  flex-direction: row;
}

.userCurrentStatusChange>h3 {
  font-size: 22px;
  font-weight: 700;
  color: var(--connected-orange);
  margin-left: 10px;
}

.userCurrentStatusChange>p {
  font-size: 12px;
  font-weight: 500;
  color: black;
  margin-top: 10px;
  margin-left: 10px;
}

.userCurrentStatusChange>p:hover {
  cursor: pointer;
  text-decoration: underline;
  color: var(--connected-color);
}

.verifiedBySchool {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.verifiedBySchool>p,
.notYetVerified>p {
  font-size: 12px;
  font-weight: 700;
  color: var(--connected-color);
  margin-right: 50px;
}

.verifiedBySchool>.MuiSvgIcon-root {
  color: var(--connected-color);
  font-size: 15px !important;
}

.StatusUpdate {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.StatusUpdateButton>button {
  background-color: var(--connected-color);
  border: 1px solid var(--connected-color);
  color: white;
  font-size: 13px;
  font-weight: 500;
  text-transform: inherit;
  /* border-radius: 10px; */
  width: 60px;
  height: 25px;
  border-radius: 4px;
  margin-top: 10px;
  margin-left: 10px;
}

.StatusUpdateButton>button:hover {
  background-color: white;
  border: 1px solid var(--connected-color);
  color: var(--connected-color);
}

.sidebar__nameParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
}

.sidebar__nameParent>h5 {
  margin-left: 10px;
  width: 200px;
  font-size: 16px;
}

.sidebar__nameParent>h5:hover {
  cursor: pointer;
  text-decoration: underline;
}