.header {
  display: flex;
  padding: 7px 20px;
  max-width: 1300px;
  /* width: 1300px; */
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 100;
  box-shadow: 0px 5px 8px -9px rgba(0, 0, 0, 0.75);
}

.header__left {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.header__left>h1 {
  font-size: 35px;
  /* margin-right: 20px; */
  margin-left: 5px;
  /* padding-right: 10px; */
  color: var(--connected-color);
  /* margin-left: 100px; */
}

.header__searchInput {
  display: flex;
  align-items: center;
  background-color: var(--connected-background);
  padding: 5px;
  border-radius: 4px;
  margin-left: 10px;
  width: 250px;
  padding: 12px;
}

.header__searchInput>.MuiSvgIcon-root {
  color: gray;
}

.header__searchInput>input {
  height: 20px;
  background-color: var(--connected-background);
  border: none;
  outline-width: 0;
}

.header__searchInput>input::-webkit-input-placeholder {
  font-size: 16px;
  font-weight: 500;
  color: gray;
  /* padding-left: 10px; */
}

.header__left>button {
  background-color: var(--connected-color);
  border: 1px solid var(--connected-color);
  color: white;
  font-weight: 900;
  text-transform: inherit;
  border-radius: 4px;
  width: 70px;
  height: 40px;
  margin-left: 5px;
}

.header__left>button:hover {
  background-color: white;
  border: 1px solid var(--connected-color);
  color: var(--connected-color);
}

.header__right {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.header__options {
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  align-items: center;
}

.header__option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100px;
  height: 50px;
}

.header__option:hover {
  background-color: var(--connected-color);
  border-radius: 4px;
  color: white;
}

/* .header__burger {
  display: none;
} */
.header__option>.MuiSvgIcon-root {
  font-size: 20px;
}

.header__option>h3 {
  font-size: 13px;
}

.header__optionHome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100px;
  height: 50px;
}

.header__optionHome:hover {
  background-color: var(--connected-color);
  border-radius: 4px;
  color: white;
}

.header__optionHome>.MuiSvgIcon-root {
  font-size: 20px;
}

.header__optionHome>h3 {
  font-size: 13px;
}

.header__optionProfile {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
}

.header__optionProfile:hover {
  background-color: lightgray;
  border-radius: 4px;
  color: var(--connected-color);
}

#messagesTitle,
#requestsTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#messagesTitle>p,
#requestsTitle>p {
  font-size: 16px;
  font-weight: 700;
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--connected-color);
}

#messagesTitle>h5,
#requestsTitle>h5 {
  font-size: 14px;
  font-weight: 500;
  margin-right: 30px;
  color: black;
}

#messagesTitle>h5:hover,
#requestsTitle>h5:hover {
  cursor: pointer;
  text-decoration: underline;
  color: var(--connected-color);
}

.headerNoData>h4 {
  font-size: 16px;
  font-weight: 600;
  color: var(--connected-orange);
  margin-left: 50px;
}

#requests,
#messages,
#notifications,
#avatar {
  border-radius: 4px;
}

#notifications>p {
  font-size: 16px;
  font-weight: 700;
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--connected-color);
}

#messages>hr {
  margin-top: 0px !important;
}

#requests>hr,
#messages>hr,
#notifications>hr {
  width: 95% !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.avatarDropdown__profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
}

.avatarDropdown__profile>h5 {
  margin-left: 10px;
  font-size: 20px;
  font-weight: 700;
}

.avatarDropdown__profile>h5:hover {
  cursor: pointer;
  text-decoration: underline;
}

#avatar>hr {
  width: 90% !important;
  margin-left: auto;
  margin-right: auto;
}

.dropdowndeletAccount {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

.dropdowndeletAccount>h5 {
  text-align: end;
  font-size: 11px;
  font-weight: 500;
  margin-left: 2px;
}

.dropdowndeletAccount>.MuiSvgIcon-root {
  font-size: 14px;
  font-weight: 500;
}

.dropdowndeletAccount>h5:hover {
  cursor: pointer;
  text-decoration: underline;
}

.HeaderBottomRow {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--connected-background);
  border-radius: 5px;
}

.complaintMobEmail {
  display: flex;
  flex-direction: column;
}

.complaintMobEmail>h4 {
  font-size: 12px;
  font-weight: 700;
  color: black;
}

.complaintMobEmail>p {
  font-size: 12px;
  font-weight: 400;
  color: black;
}

.avatarDropdown__logout {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  width: 300px;
  height: 40px;
  border-radius: 4px;
}

.avatarDropdown__logout:hover {
  cursor: pointer;
  background-color: lightgray;
}

.avatarDropdown__logout>.MuiSvgIcon-root {
  font-size: 20px;
  font-weight: 700;
  margin-right: 10px;
  color: gray;
  margin-left: 40px;
}

.avatarDropdown__logout>h5 {
  font-size: 16px;
  font-weight: 700;
}

.seeAllSent {
  width: 580px;
  height: 700px;
}

.seeAllSent>hr {
  border: 1px solid lightgray;
  width: 550px !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.seeAllSent>h3 {
  font-size: 20px;
  font-weight: 600;
  color: #2e81f4;
  margin-top: 20px;
  margin-left: 50px;
}

.noSentRequest>h4 {
  font-size: 16px;
  font-weight: 600;
  color: rgb(241, 162, 15);
  text-align: center;
}

.seeAllSentCards {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 3px;
  padding: 10px;
}

.seeAllSentCardsBlock,
.ButtonSeeMore,
.noMoreSchools {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ButtonSeeMore>button {
  background-color: var(--connected-color);
  border: 1px solid var(--connected-color);
  color: white;
  font-weight: 700;
  text-transform: inherit;
  border-radius: 4px;
  width: 170px;
  height: 27px;
  /* margin-left: 5px; */
  margin-top: 10px;
}

.ButtonSeeMore>button:hover {
  background-color: white;
  border: 1px solid var(--connected-color);
  color: var(--connected-color);
}

.noMoreSchools>h4 {
  font-size: 16px;
  font-weight: 700;
  color: var(--connected-orange);
  margin-top: 15px;
}

.imgLogoName {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 100px;
}

/* to make it mobile/tablet size */
/* @media (max-width: 965px) {
  .header {
    box-shadow: 0px 5px 8px -9px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    padding: 30px 10px;
    margin-left: auto;
    margin-right: auto;
    position: sticky;
    top: 0;
    z-index: 100;
  }
  .header__left {
    background-color: var(--connected-color);
    border-radius: 30px;
    padding: 20px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  .header__left > h1 {
    font-size: 50px;
    padding-right: 10px;
    color: white;
  }
  .header__searchInput {
    display: flex;
    align-items: center;
    background-color: var(--connected-background);
    padding: 5px;
    border-radius: 15px;
    margin-left: 10px;
    min-width: 500px;
    padding: 12px;
  }
  .header__searchInput > .MuiSvgIcon-root {
    font-size: 40px;
  }
  .header__searchInput > input {
    height: 43px;
    border: none;
    outline-width: 0;
    width: 100%;
  }
  .header__left > button {
    border: none;
    height: 68px;
    color: var(--connected-color);
    font-size: 22px;
    font-weight: 600;
    background-color: white;
    border-radius: 15px;
    width: 100px;
  }
  .header__right {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px 0;
    background-color: white;
    border-radius: 30px;
  }
  .header__options {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .header__burger {
    display: inline;
  }
  .header__option {
    color: white;
  }
  .header__option > h3 {
    display: none;
  }
  .header__option > .MuiSvgIcon-root {
    font-size: 70px;
    color: var(--connected-color);
  }
} */