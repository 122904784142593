.SidebarESchoolingStatusAddStudent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 50px;
}
.studentYesNo,
.studentInputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.studentInputs > button {
  background-color: var(--connected-color);
  border: none;
  color: white;
  font-weight: 700;
  text-transform: inherit;
  border-radius: 3px;
  width: 350px;
  height: 35px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 700;
}
.studentInputs > button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}
.studentInputs > h5 {
  font-size: 16px;
  font-weight: 700;
  color: green;
}
