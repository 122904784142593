.FindToFollowSchools {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.FindToFollowSchoolsRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.FindToFollowSchools > h2 {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-left: 10px;
}
.FindToFollowSchoolsRow > .MuiCard-root {
  margin-left: 10px;
  border: 1px solid lightgray;
  height: 240px;
}
.FindToFollowSchoolsCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}
.FindToFollowSchoolsCard > p {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-top: 5px;
  height: 100px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
}
.FindToFollowSchoolsCard > h5 {
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  margin-top: 18px;
  color: rgb(241, 162, 15);
}
.FindToFollowSchoolsCard > p:hover,
.FindToFollowSchools > h2:hover {
  text-decoration: underline;
  cursor: pointer;
}
.FindToFollowSchoolsCard > button {
  background-color: #2e81f4;
  border: none;
  color: white;
  font-size: 13px;
  font-weight: 600;
  text-transform: inherit;
  border-radius: 3px;
  height: 25px;
  width: 100px;
  margin-top: 15px;
}
.FindToFollowSchoolsCard > button:hover {
  background-color: white;
  color: #2e81f4;
  border: 1px solid #2e81f4;
}
.seeAllSchools {
  padding: 10px;
}
.seeAllSchoolsList {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 10px;
}
.seeAllSchoolsList > h5 {
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  margin-left: 10px;
  color: rgb(34, 55, 241);
}
.seeAllSchoolsList > h5:hover,
.FindToFollowSchoolsCard > h5:hover {
  cursor: pointer;
  text-decoration: underline;
}
.seeAllSchoolsTitleSearch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.seeAllSchoolsTitleSearch > h3 {
  font-size: 22px;
  font-weight: 700;
  padding: 10px;
  margin-left: 20px;
  color: #2e81f4;
}
.seeAllSchoolsTitleSearch > .MuiAutocomplete-root {
  margin-right: 20px;
  width: 300px;
}
.seeAllSchools > hr {
  border: 1px solid #2e81f4;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
.seeAllSchoolsList > .MuiAvatar-root {
  width: 40px;
  height: 40px;
}
.seeAllSchoolsList > h4 {
  font-size: 16px;
  font-weight: 700;
  margin-left: 10px;
  width: 440px;
  /* background-color: red; */
}
.seeAllSchoolsList > h4:hover {
  cursor: pointer;
  text-decoration: underline;
}
.seeAllSchoolsList > button {
  background-color: rgb(18, 113, 223);
  color: white;
  border: none;
  font-size: 13px;
  font-weight: 600;
  text-transform: inherit;
  border-radius: 3px;
  height: 30px;
  width: 70px;
  margin-top: 5px;
  margin-left: 10px;
}
.seeAllSchoolsList > button:hover {
  background-color: white;
  color: rgb(18, 113, 223);
  border: 1px solid rgb(18, 113, 223);
}
.removeFrndButton > button {
  background-color: rgb(241, 162, 15);
  color: white;
  border: none;
  font-size: 13px;
  font-weight: 600;
  text-transform: inherit;
  border-radius: 3px;
  height: 25px;
  width: 100px;
  margin-top: 15px;
}
.removeFrndButton > button:hover {
  background-color: white;
  color: rgb(241, 162, 15);
  border: 1px solid rgb(241, 162, 15);
}
.schoolsAllFollow {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 1px;
  padding: 10px;
}
.schoolSelf > h4 {
  font-size: 16px;
  font-weight: 700;
  color: green;
  margin-top: 15px;
}
.showMoreSchools {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ButtonSeeMore > button {
  background-color: rgb(18, 113, 223);
  color: white;
  border: none;
  font-size: 13px;
  font-weight: 600;
  text-transform: inherit;
  border-radius: 3px;
  height: 30px;
  width: 120px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.ButtonSeeMore > button:hover {
  background-color: white;
  color: rgb(18, 113, 223);
  border: 1px solid rgb(18, 113, 223);
}
.noMoreSchools > h4 {
  font-size: 18px;
  font-weight: 700;
  color: rgb(241, 162, 15);
  margin-top: 10px;
  margin-bottom: 10px;
}
