.verifyButtonBut > button {
  background-color: var(--connected-color);
  border: 1px solid var(--connected-color);
  color: white;
  font-size: 14px;
  font-weight: 600;
  text-transform: inherit;
  border-radius: 2px;
  height: 30px;
  margin-top: 5px;
  margin-right: 10px;
}
.verifyButtonBut > button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}
.sendMsg > button {
  width: 130px;
}
.followSchool > button {
  width: 80px;
}
.unFollowSchool > button {
  width: 90px;
}
.unFollowSchool > button {
  background-color: var(--connected-orange);
  border: 1px solid var(--connected-orange);
  color: white;
  font-size: 14px;
  font-weight: 600;
  text-transform: inherit;
  border-radius: 2px;
  height: 30px;
  margin-top: 5px;
  margin-right: 10px;
}
.unFollowSchool > button:hover {
  background-color: white;
  color: var(--connected-orange);
  border: 1px solid var(--connected-orange);
}
