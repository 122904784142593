/* TEACHING STAFF */
.SchoolVerificationRequestsTeachingStaff {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-left: 80px;
}
.SchoolVerificationRequestsTeachingStaff > hr {
  width: 700px !important;
  border: 1px solid lightgray !important;
  margin-bottom: 20px;
  margin-top: 20px;
}
.schoolverificationrequests__containerRightTeachingStaffData {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.schoolverificationrequests__containerRightTeachingStaffData > p {
  font-size: 17px;
  font-weight: 700;
  color: var(--connected-orange);
  width: 230px;
  /* background-color: var(--connected-color); */
}
.schoolverificationrequests__containerRightTeachingStaffDataName {
}
.schoolverificationrequests__containerRightTeachingStaffDataName > h3 {
  font-size: 16px;
  font-weight: 700;
  width: 300px;
  margin-left: 10px;
  color: black;
}
.schoolverificationrequests__containerRightTeachingStaffDataButtons {
}
.schoolverificationrequests__containerRightTeachingStaffDataButtons > button {
  background-color: var(--connected-color);
  border: none;
  color: white;
  font-weight: 700;
  text-transform: inherit;
  border-radius: 3px;
  width: 70px;
  height: 30px;
  margin-left: 10px;
}
.schoolverificationrequests__containerRightTeachingStaffDataButtons
  > button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}

.subjectDataRow,
.schoolverificationrequests__containerRightTeachingStaffDataName,
.schoolverificationrequests__containerRightTeachingStaffDataButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.subjectDataRow {
  margin-top: 5px;
}
.memberVerAndreq > h3 {
  font-size: 15px;
  font-weight: 700;
  color: var(--connected-color);
  width: 500px;
  margin-top: 10px;
}

.SchoolVerificationRequestsTeachingStaff__verifiedMember > h3 {
  font-size: 15px;
  font-weight: 700;
  color: var(--connected-color);
}
.SchoolVerificationRequestsTeachingStaff__verifiedMember > h3 {
}
.SchoolVerificationRequestsTeachingStaff__noRequest > h3 {
  font-size: 16px;
  font-weight: 700;
  color: var(--connected-orange);
}
/* TEACHING STAFF */
