.schoolhomepagesidebar {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 4px;
  position: sticky;
  top: 70px;
  z-index: 1;
}
.schoolhomepagesidebar__school {
  margin-top: 20px;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.schoolhomepagesidebar__school > .MuiAvatar-root {
  cursor: pointer;
}
.schoolhomepagesidebar__school > h5 {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
}
.schoolhomepagesidebar__school > h5:hover {
  text-decoration: underline;
}
.schooleducationSidebar__top {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 30px;
  /* padding-top: 15px; */
}
.schooleducationSidebar__option {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 33px;
}
.schooleducationSidebar__option:hover {
  background-color: var(--connected-color);
  color: white !important;
  border-radius: 4px;
}
.schooleducationSidebar__option > p {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 700;
}
.schooleducationSidebar__option > .MuiSvgIcon-root {
  font-size: 20px;
}
