.photosContainer {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.userphotos > h2 {
  font-size: 18px;
  font-weight: 700;
  margin-left: 20px;
  color: var(--connected-color);
}
.userphotos__profilepics {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.userphotos__profilepics > hr {
  width: 100%;
  margin-top: 10px;
  border: 1px solid var(--connected-color);
  margin-bottom: 20px;
}
#gridContainerPhotos {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 8px;
  padding: 10px;
}
#gridContainerPhotos > img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
}
#gridContainerPhotos > img:hover {
  cursor: pointer;
}
.noImagesToShow > h3 {
  font-size: 18px;
  font-weight: 700;
  color: var(--connected-orange);
  margin-left: 50px;
}
/* .imageDiv > .MuiSvgIcon-root {
  color: gray;
  margin-left: 110px;
  padding: 10px;
} */
/* .imageDiv > .MuiSvgIcon-root:hover {
  cursor: pointer;
  color: var(--connected-color);
} */
.imageOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  padding-top: 5px;
  margin-bottom: 20px;
}
.imageOptions > h3 {
  font-size: 12px;
  font-weight: 500;
  padding-left: 5px;
  color: red;
  cursor: pointer;
  margin-right: 10px;
}
.imageOptions > h4 {
  font-size: 12px;
  font-weight: 500;
  color: var(--connected-color);
  cursor: pointer;
}

.imageOptions > h3:hover,
.imageOptions > h4:hover {
  text-decoration: underline;
}

.removePic,
.removePicText,
.makeProfilePic,
.makeProfilePicText {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.removePicText > h4,
.makeProfilePicText > h4 {
  font-size: 20px;
  font-weight: 600;
  color: black;
  margin-bottom: 10px;
}
.removePicButtons,
.makeProfilePicButtons {
  margin-top: 20px;
}
.removePicButtons > button,
.makeProfilePicButtons > button {
  background-color: #1976d2;
  color: white;
  border: 1px solid #1976d2;
  font-size: 15px;
  font-weight: 600;
  text-transform: inherit;
  border-radius: 3px;
  height: 35px;
  width: 80px;
  margin-left: 10px;
  margin-right: 10px;
}
.removePicButtons > button:hover,
.makeProfilePicButtons > button:hover {
  background-color: white;
  color: #1976d2;
  border: 1px solid #1976d2;
}
