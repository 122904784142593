.SchoolEvents {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.SchoolEvents > h2 {
  font-size: 22px;
  font-weight: 700;
  margin-left: 20px;
  color: var(--connected-color);
}
.SchoolEvents > hr {
  width: 100%;
  margin-top: 10px;
  border: 1px solid var(--connected-color);
  margin-bottom: 20px;
}
.noEventsSchool > h4 {
  font-size: 18px;
  font-weight: 700;
  margin-left: 50px;
  color: var(--connected-orange);
}
