.schoolabout {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.schoolabout__Title {
  min-width: 180px;
  color: var(--connected-color);
}
.schoolabout__Info {
  min-width: 400px;
  max-width: 550px;
}
.schoolabout__basicInfo,
.schoolabout__contact,
.schoolabout__visionmission,
.schoolabout__facilities,
.schoolabout__otherinfo,
.schoolabout__staff {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  /* border-bottom: 1px solid #000; */
  margin-left: 20px;
  margin-bottom: 5px;
  padding: 10px 20px;
}
.schoolabout__InfoVision > h4,
.schoolabout__InfoMission > h4 {
  margin-left: 50px;
  margin-bottom: 10px;
  color: black;
  font-size: 16px;
  font-weight: 700;
  /* margin-top: 20px; */
}

.schoolabout__InfoVision > p,
.schoolabout__InfoMission > p {
  font-size: 15px;
  font-weight: 500;
  margin-left: 10px;
  margin-bottom: 20px;
  /* background-color: red; */
}
.schoolabout__InfoAddress,
.schoolabout__InfoPhone,
.schoolabout__InfoEmail {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.schoolabout__InfoAddress > .MuiSvgIcon-root,
.schoolabout__InfoPhone > .MuiSvgIcon-root,
.schoolabout__InfoEmail > .MuiSvgIcon-root {
  font-size: 20px;
  font-weight: 800;
  color: gray;
  margin-right: 10px;
}
.schoolabout__InfoAddress > p,
.schoolabout__InfoPhone > p,
.schoolabout__InfoEmail > p {
  font-size: 14px;
  font-weight: 600;
}

.schoolabout__InfoEstablished {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}
.schoolabout__InfoEstablished > p {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
}
.schoolabout__InfoEstablished > .MuiSvgIcon-root {
  font-size: 20px;
  color: gray;
}
.schoolabout__facilitieslist {
  display: flex;
  flex-direction: column;
}
.schoolabout__facilitieslist > p {
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
  margin-bottom: 4px;
}
.schoolabout__otherinfoBio > h4 {
  color: gray;
  font-size: 16x;
  font-weight: 600;
  margin-left: 40px;
  margin-bottom: 5px;
}
.schoolabout__otherinfoBio > p {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 20px;
}

.schoolabout__otherinfoBioAchievementsList {
  display: flex;
  flex-direction: column;
}

.schoolabout__otherinfoBioAchievementsList {
  margin-left: 20px;
  margin-bottom: 20px;
}

.schoolabout__otherinfoBioAchievementsList > p {
  font-size: 14px;
  font-weight: 500;
}
.schoolabout__staffTeaching > h4 {
  font-size: 16px;
  margin-left: 10px;
  font-weight: 700;
  margin-bottom: 10px;
  color: gray;
}
.schoolabout__staffTeachingTeachersList {
  margin-bottom: 20px;
}
.schoolabout__staffTeachingPrincipal {
  display: flex;
  flex-direction: row;
}
.schoolabout__staffTeachingPrincipal {
  align-items: center;
}
.schoolabout__staffTeachingPrincipal > h5,
.schoolabout__staffTeachingTeachers > h5 {
  margin-right: 20px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--connected-color);
}
.schoolabout__staffTeachingTeachers > h5 {
  width: 120px !important;
}
.addAchievements > p {
  font-size: 20px !important;
}
.schoolabout__staffTeachingPrincipal > p {
  margin-bottom: 20px;
}
.schoolabout__staffTeachingPrincipal,
.schoolabout__staffTeachingTeachers {
  margin-bottom: 20px;
}
.schoolabout__staffTeachingPrincipal > p,
.schoolabout__staffTeachingTeachersList > p {
  margin-left: 30px;
  /* color: gray; */
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.schoolabout__staffTeachingPrincipal > p:hover,
.schoolabout__staffTeachingTeachersList > p:hover {
  color: var(--connected-color);
}
.staffNameLinkPrinci,
.staffNameLinkTeachers {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.staffNameLinkPrinci > h3:hover,
.staffNameLinkTeachers > h3:hover {
  cursor: pointer;
  text-decoration: underline;
}
.staffNameLinkPrinci > h3,
.staffNameLinkTeachers > h3 {
  font-size: 14px;
  font-weight: 600;
  margin-left: 5px;
}
.staffNameLinkPrinci > p,
.staffNameLinkTeachers > p {
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
}

.staffNameLinkTeachers {
  width: 630px;
  margin-left: 20px;
}
.showMoreBtnTeachNew>button{
  background-color: var(--connected-color);
  border: 1px solid var(--connected-color);
  color: white;
  font-weight: 760;
  text-transform: inherit;
  border-radius: 2px;
  width: 250px;
  height: 30px;
  margin-top: 5px;
  margin-right: 5px;
}
.showMoreBtnTeachNew > button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}
.noMoreTeach>h4{
  font-size: 20;
  font-weight: 700;
  color: var(--connected-orange);
}