.schoolaccounttop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  margin-top: 5px;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 4px;
}
.schoolaccounttop__image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
#schoolaccounttop__image > .MuiAvatar-root {
  cursor: pointer;
}
.schoolaccounttop__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.schoolaccounttop__info > h5 {
  padding: 8px 0;
  font-size: 25px;
  font-weight: 700;
  max-width: 700px;
  /* color: var(--connected-color); */
}
.schoolaccounttop__info > p {
  font-size: 14px;
  color: black;
}
.verifyButton {
  display: flex;
  flex-direction: row;
}
.verifyButton > button {
  background-color: var(--connected-color);
  border: none;
  color: white;
  font-weight: 700;
  text-transform: inherit;
  height: 30px;
  margin-top: 5px;
  width: 120px;
  font-size: 15px;
}
.verifyButton > button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}
.editSchoolProfile {
  width: 580px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.editSchoolProfile > h2 {
  font-size: 20px;
  font-weight: 800;
  margin-top: 20px;
  margin-bottom: 10px;
  /* color: gray; */
}

.editSchoolProfilePic,
.editSchoolProfileSchoolName,
.editSchoolProfileSchoolAddress,
.editSchoolProfileVision,
.editSchoolProfileMission,
.editSchoolProfileAboutShcool,
.editSchoolProfilePhoneNo,
.editSchoolProfileEmail,
.editSchoolProfileEstaYear,
.editSchoolProfileFacilities,
.editSchoolProfileAchievements,
.editSchoolProfileAffiliation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.editSchoolFacilities {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.editSchoolProfilePic > button,
.editSchoolFacilities > button,
.editSchoolProfileButtons>button {
  font-size: 14px;
  font-weight: 700;
  height: 30px;
  margin-top: 5px;
  text-decoration: inherit;
  background-color: #2e81f4;
  border: 1px solid #2e81f4;
  color: white;
  text-transform: inherit;
}
.editSchoolProfilePic > button:hover,
.editSchoolFacilities > button:hover,
.editSchoolProfileButtons>button:hover {
  background-color: white;
  border: 1px solid #2e81f4;
  color: #2e81f4;
}
.editSchoolProfileSchoolAddressData {
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.editSchoolProfileSchoolAddress > .MuiFormControl-root {
  margin-bottom: 5px;
}
.editSchoolProfileSchoolAddressData > .MuiFormControl-root {
  margin-right: 5px;
}
.editSchoolProfileFacilitiesRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.editSchoolProfileFacilitiesRowLeft,
.editSchoolProfileFacilitiesRowRight {
  width: 230px;
}

.editSchoolProfileAchievements > .MuiTextField-root {
  margin-bottom: 5px;
}
.editSchoolProfilePic > h2,
.editSchoolProfileSchoolName > h2,
.editSchoolProfileSchoolAddress > h2,
.editSchoolProfileVision > h2,
.editSchoolProfileMission > h2,
.editSchoolProfileAboutShcool > h2,
.editSchoolProfilePhoneNo > h2,
.editSchoolProfileEmail > h2,
.editSchoolProfileEstaYear > h2,
.editSchoolProfileFacilities > h2,
.editSchoolProfileAchievements > h2,
.editSchoolProfileAffiliation > h2,
.editSchoolFacilities > h2 {
  font-size: 16px;
  font-weight: 700;
  color: green;
}
.editSchoolProfileSchoolName > h5 {
  font-size: 13px;
  font-weight: 400;
  color: orange;
  width: 500px;
}
.schoolNameAppearance {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 510px;
}
.schoolNameAppearance > h3 {
  font-size: 14px;
  font-weight: 500;
}
