.userprofilesidebar {
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  border-radius: 4px;
  background-color: white;
  padding: 10px;
  position: sticky;
  top: 125px;
}
.userprofilesidebar__bio {
  padding: 10px;
  border-bottom: 1px solid lightgray;
}
.userprofilesidebar__bio > p {
  padding: 10px;
  font-size: 15px;
  font-weight: 500;
}
.userprofilesidebar__bio > h4 {
  font-size: 16px;
  font-weight: 700;
}
.userprofilesidebar__intro {
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: gray;
}
.userprofilesidebar__intro > h4 {
  color: black;
  font-size: 16px;
  font-weight: 700;
  color: var(--connected-color);
}
.userprofilesidebar__introOptions {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.userprofilesidebar__introOption {
  display: flex;
  flex-direction: row;
  padding: 5px;
  align-items: center;
  margin-bottom: 2px;
  justify-content: flex-start;
}
.userprofilesidebar__introOption > p {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
}
.userprofilesidebar__introOption > .MuiSvgIcon-root {
  font-size: 18px;
  color: gray;
}
