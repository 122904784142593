.HowToUse {
    /* display: flex; */
    /* flex-direction: column; */
    background-color: var(--connected-background);
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    margin-bottom: 10px;
}

.HowToUse__Body {
    width: 1000px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid lightgray;
    margin-top: 5px;
    margin-bottom: 10px;
    border-radius: 4px;
    padding: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.HowToUse>.MuiAccordion-root>.MuiAccordionSummary-root {
    background-color: rgb(245, 241, 234);
}

.HowToUse>.MuiAccordion-root>.MuiAccordionSummary-root:hover {
    background-color: rgb(241, 226, 199);
}

.HowToUse__Body>h2 {
    font-size: 20px;
    font-weight: 700;
    color: var(--connected-color);
    /* margin-bottom: 30px; */
    margin-top: 20px;
    text-align: center;
}

.HowToUse__Body>h3 {
    text-align: left;
    font-size: 16px;
    font-weight: 700;
    color: black;
    margin-bottom: 10px;
    margin-top: 30px;
    padding-left: 50px;
}

.HowToUseSummary>h3 {
    font-size: 18px;
    font-weight: 700;
    color: var(--connected-orange);
    margin-left: 20px;
}

.HowToData {
    margin-bottom: 30px;
}

.HowToUseDetails>h2 {
    font-size: 16px;
    font-weight: 700;
    color: black;
    margin-left: 20px;
    margin-bottom: 5px;
    /* width: 900px; */
}

.HowToData>h3 {
    font-size: 18px;
    font-weight: 700;
    color: var(--connected-color);
    margin-left: 60px;
    margin-bottom: 10px;
}

.HowToData>h4 {
    font-size: 16px;
    font-weight: 700;
    color: black;
    margin-left: 20px;
    margin-bottom: 5px;
    width: 900px;
}

.HowToData>p {
    font-size: 16px;
    font-weight: 500;
    color: black;
    margin-left: 80px;
    margin-bottom: 10px;
}

.HowToDataData {
    margin-top: -15px;
    margin-bottom: 10px;
}

.HowToDataData>p {
    font-size: 16px;
    font-weight: 500;
    color: black;
    margin-left: 120px;

    /* margin-bottom: 10px; */
}