.SchoolAddOrRemove {
  /* display: flex; */
  /* flex-direction: column; */
  background-color: var(--connected-background);
  height: 100vh;
  /* width: 100%; */
}

.SchoolAddOrRemove__Container {
  width: 1200px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid lightgray;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 10px;
  height: 100vh;
}

.SchoolAddOrRemove__Container>hr {
  width: 100% !important;
  margin-bottom: 20px;
  margin-top: 20px;
  border: 1px solid var(--connected-color);
}

.SchoolAddOrRemove__ContainerTop {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SchoolAddOrRemove__ContainerTop>h3 {
  font-size: 22px;
  font-weight: 800;
  color: var(--connected-color);
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.SchoolAddOrRemove__ContainerTop>h5 {
  font-size: 14px;
  font-weight: 700;
  color: gray;
}

.SchoolAddOrRemove__ContainerTop>h4 {
  font-size: 16px;
  font-weight: 800;
  color: gray;
}

.SchoolAddOrRemove__ContainerTop>hr {
  width: 100% !important;
  margin-bottom: 20px;
  margin-top: 20px;
  border: 1px solid var(--connected-color);
}

.SchoolAddOrRemove__ContainerBottom {
  display: flex;
  flex-direction: row;
}

.SchoolAddOrRemove__ContainerBottomLeft {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  position: sticky;
  top: 80px;
}

.SchoolAddOrRemove__ContainerBottomRight {
  width: 900px;
}

.schoolClass,
.schoolClassSubject {
  display: flex;
  flex-direction: column;
  margin-left: 40px !important;
}

.schoolClassAdd,
.schoolClassRemove,
.schoolClassSubjectAdd,
.schoolClassSubjectRemove {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  /* background-color: red; */
  margin: 5px;
  color: gray;
}

.schoolClassAdd:hover,
.schoolClassRemove:hover,
.schoolClassSubjectAdd:hover,
.schoolClassSubjectRemove:hover {
  color: var(--connected-color);
}

.schoolClassAdd>h2,
.schoolClassRemove>h2,
.schoolClassSubjectAdd>h2,
.schoolClassSubjectRemove>h2 {
  font-size: 16px;
  font-weight: 700;
  /* color: gray; */
  /* margin: 5px; */
}

.SchoolAddOrRemove__ContainerBottomRight {
  display: flex;
  flex-direction: column;
  /* background-color: red; */
}

.SchoolAddOrRemove__radioMediumMui,
.SchoolAddOrRemove__radioMediumMuiDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SchoolAddOrRemove__radioMediumMui {
  margin-left: 20px;
}

.SchoolAddOrRemove__AddClass {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* background-color: red; */
  width: 600px;
}

.SchoolAddOrRemove__AddClass>h2 {
  font-size: 20px;
  font-weight: 800;
  color: var(--connected-color);
  text-align: center;
}

.SchoolAddOrRemove__Button>button {
  color: white;
  background-color: var(--connected-color);
  cursor: pointer;
  border: 1px solid var(--connected-color);
  text-transform: inherit;
  border-radius: 4px;
  width: 200px;
  height: 35px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 600;
  /* margin-left: auto;
  margin-right: auto; */
}

.SchoolAddOrRemove__Button>button:hover {
  color: var(--connected-color);
  background-color: white;
  border: 1px solid var(--connected-color);
}

.SchoolAddOrRemove__AddClass,
.SchoolAddOrRemove__AddClassDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SchoolAddOrRemoveClassSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SchoolAddOrRemove__schoolClass,
.SchoolAddOrRemove__schoolClassSection,
.SchoolAddOrRemove__schoolClassSectionSubject {
  margin-top: 10px;
}

.SchoolAddOrRemove__radio {
  margin-top: 20px;
}

.afterClickMsg {
  display: flex;
}

.afterClickMsg>h4 {
  font-size: 18px;
  font-weight: 700;
  color: rgb(7, 204, 7);
  margin-top: 20px;
}

.selectedSubjectsList {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
  background-color: var(--connected-color);
  color: white;
  padding: 7px;
  border-radius: 5px;
}

.title>h2 {
  font-size: 20px;
  font-weight: 700;
  width: 250px;
}

.title:hover {
  cursor: pointer;
}