.postsender {
  border: 1px solid lightgray;
  padding: 20px;
  border-radius: 4px;
  background-color: white;
  margin-bottom: 5px;
}
.postsender__top {
  display: flex;
  flex-direction: row;
}
.postsender__top > .MuiAvatar-root {
  margin-top: 7px;
}
.postsender__top > .MuiFormControl-root {
  width: 400px;
  margin-left: 10px;
}
.postsender__bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-top: 20px;
}
.postsender__bottomButton > button {
  background-color: var(--connected-color);
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 600;
  text-transform: inherit;
  border-radius: 3px;
  height: 30px;
  width: 50px;
  margin-left: 10px;
  margin-right: 10px;
}
.postsender__bottomButton > button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}
.postsender > h4 {
  font-size: 18px;
  font-weight: 700;
  color: red;
  margin-top: 10px;
  text-align: center;
}
.postsender > h3 {
  font-size: 18px;
  font-weight: 700;
  color: green;
  margin-top: 10px;
  text-align: center;
}
.postsender__bottomUpload {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.postsender__bottomUpload>h3 {
  
}
.imageUpload__progress {
  width: 250px;
}
.uploadedImage{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.uploadedImage > img {
  max-width: 70px;
  max-height: 70px;
  border-radius: 5px;
  margin: 5px;
}
.uploadedImages{
  display: flex;
  flex-direction: row;

}