.createnewaccountinfo {
  height: 100%;
  display: grid;
  background-color: var(--connected-background);
  place-items: center;
}
.createnewaccountinfo__container {
  margin-left: auto;
  margin-right: auto;
  border: 1px solid lightgray;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 80px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.createnewaccountinfo__container > h1 {
  font-size: 50px;
  font-weight: 700;
  color: var(--connected-color);
}
.createnewaccountinfo__container > p {
  font-size: 18px;
  font-weight: 400;
  color: gray;
  margin-left: 20px;
}
.createnewaccountinfo__container > hr {
  width: 100%;
  border: 1px solid var(--connected-color);
  margin-bottom: 10px;
  margin-top: 20px;
}
.createnewaccountinfo__containerSpecify {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.createnewaccountinfo__containerSpecify > button {
  color: white;
  background-color: var(--connected-color);
  cursor: pointer;
  border: 1px solid var(--connected-color);
  font-size: 13px;
  font-weight: 700;
  text-transform: inherit;
  border-radius: 4px;
  width: 200px;
  height: 35px;
  margin-top: 30px;
  font-size: 18px;
  font-weight: 700;
  margin-left: auto;
  margin-right: auto;
}
.createnewaccountinfo__containerSpecify > button:hover {
  color: var(--connected-color);
  background-color: white;
  border: 1px solid var(--connected-color);
}
.createnewaccountinfo__containerSpecifyTop {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
}

.createnewaccountinfo__containerSpecifyTop > h2 {
  font-size: 24px;
  font-weight: 700;
  margin-right: 50px;
  color: var(--connected-orange);
}
.Options {
  display: flex;
  flex-direction: column;
}
.radioOption {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}
.option > h4 {
  font-size: 18px;
  font-weight: 700;
  margin-left: 10px;
}
.option > p {
  font-size: 14px;
  font-weight: 700;
  margin-left: 10px;
  color: gray;
}
.radioOption > input[type="radio"] {
  margin-top: 6px;
  cursor: pointer;
  background-color: aqua;
}
.createnewaccountinfo__containerSpecifyTop
  > .MuiFormControl-root
  > .MuiFormHelperText-root
  > p {
  font-size: 14px;
  font-weight: 600;
  margin-right: 150px;
  width: 260px;
}
