.ProjectNHomework > .MuiAccordion-root > .MuiAccordionSummary-root {
  background-color: rgb(247, 246, 246);
}
.ProjectNHomework > .MuiAccordion-root > .MuiAccordionSummary-root:hover {
  background-color: rgb(209, 206, 206);
}
.PrincipalClassNSubject,
.PrincipalClassNSubjectHead {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.subRowPanelDataUploadedOn > h4 {
  width: 100px;
}
.subRowPanelDataSubmitOn > h4 {
  width: 130px;
}
.subRowPanelDataUploadedOn > h4,
.subRowPanelDataSubmitOn > h4 {
  font-size: 13px;
  font-weight: 600;
  color: gray;
}
.subRowPanelDataUploadedOn > h3,
.subRowPanelDataSubmitOn > h3 {
  font-size: 13px;
  font-weight: 700;
  color: black;
  /* width: 220px; */
}
.subRowPanelDataUploadedOn > h3 {
  width: 150px;
}
.subRowPanelDataSubmitOn > h3 {
  width: 200px;
}
.PrincipalClassNSubjectImage {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.PrincipalClassNSubjectImage > img {
  max-width: 400px;
  max-height: 400px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.PrincipalClassNSubjectImage > img:hover {
  cursor: pointer;
}
.PrincipalClassNSubjectQuestion > h4 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
  color: black;
  width: 700px;
}
.subRowPanelDataAccordianDates {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 50px;
}
.subRowPanelDataAccordianDates > h5 {
  font-size: 14px;
  font-weight: 600;
  color: gray;
  width: 150px;
}
.subRowPanelDataAccordianDates > p {
  font-size: 14px;
  font-weight: 600;
  color: gray;
  width: 10px;
}
.subRowPanelDataAccordianDates > h4 {
  font-size: 14px;
  font-weight: 700;
  color: black;
  max-width: 500px;
}
.subRowPanelDataAccordianData {
  border: 1px solid lightblue;
  padding: 10px;
  border-radius: 4px;
}
