.syllabusCovered {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.syllabusCovered>button {
    background-color: var(--connected-orange);
    border: 1px solid var(--connected-orange);
    color: white;
    font-size: 15px;
    font-weight: 500;
    text-transform: inherit;
    width: 180px;
    height: 25px;
    border-radius: 4px;
    /* margin-top: 10px; */
    margin-left: 10px;
    cursor: pointer;
}

.syllabusCovered>button:hover {
    background-color: white;
    border: 1px solid var(--connected-orange);
    color: var(--connected-orange);
}

#addChapter>.MuiAccordion-root {
    width: 600px;
    margin: auto;
}

/* #addChapter>.MuiAccordion-root>.AccordionSummary-root {
    background-color: gray;
} */

#addChapter>.MuiAccordion-root>.MuiAccordionSummary-root {
    background-color: rgb(229, 229, 230);
}

#addChapter>.MuiAccordion-root>.MuiAccordionSummary-root:hover {
    background-color: rgb(207, 207, 248);
}

/* #acoordianNoticeBoard>.MuiAccordion-root>.MuiAccordionSummary-root:hover {
    background-color: rgb(219, 219, 219);
} */