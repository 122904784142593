.HomeWorksParentschildsSubject > .MuiAccordion-root {
  width: 900px;
}
.HomeWorksParentschildsSubject
  > .MuiAccordion-root
  > .MuiAccordionSummary-root {
  background-color: rgb(248, 242, 229);
}
.HomeWorksParentschildsSubject
  > .MuiAccordion-root
  > .MuiAccordionSummary-root:hover {
  background-color: rgb(250, 230, 193);
}
