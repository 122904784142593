.SidebarESchoolingStatusAddParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  width: 800px;
}
.SidebarESchoolingStatusAddParent__Name,
.SidebarESchoolingStatusAddParent__ClsSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  /* background-color: rgb(184, 159, 159); */
}
.SidebarESchoolingStatusAddParent > h5 {
  font-size: 18px;
  font-weight: 700;
  color: green;
  margin-top: 10px;
}
.SidebarESchoolingStatusAddParent > button {
  background-color: var(--connected-color);
  border: none;
  color: white;
  font-weight: 700;
  text-transform: inherit;
  border-radius: 4px;
  width: 350px;
  height: 35px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 700;
}
.SidebarESchoolingStatusAddParent > button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}
