.userabout {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.userabout__option {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.userabout__option > .MuiSvgIcon-root {
  font-size: 20px;
  /* color: gray; */
}
.userabout__option > p {
  font-size: 16px;
  font-weight: 700;
  margin-left: 10px;
  margin-top: 2px;
  color: gray;
}
.userabout__optionschool {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.userabout__optionschool > .MuiSvgIcon-root {
  font-size: 20px;
  margin-top: 5px;
}
.userabout__optionschool > p {
  font-size: 15px;
  font-weight: 600;
  margin-left: 10px;
  color: gray;
}
.userabout__Title {
  min-width: 180px;
  color: var(--connected-color);
}
.userabout__Info {
  min-width: 400px;
  max-width: 600px;
}
.userabout__InfoBio > p {
  font-size: 15px;
  font-weight: 600;
  color: gray;
}
.userabout__about,
.userabout__basic,
.userabout__contact,
.userabout__education {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 20px;
  margin-bottom: 5px;
  padding: 10px 20px;
}
.userabout__InfoStudent,
.userabout__InfoTeacher,
.userabout__InfoPrincipal {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.userabout__InfoStudent > h4,
.userabout__InfoTeacher > h4,
.princiVerfi > h4,
.studentVerif > h4 {
  font-size: 18px;
  font-weight: 700;
  color: var(--connected-orange);
}
.dataAndVerif {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}
.dataAndVerif > h5 {
  margin-left: 40px;
  font-size: 13px;
  font-weight: 600;
}
.dataAndVerif > h3 {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
  color: red;
}
.dataAndVerif > h4 {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
  color: var(--connected-color);
}
.princiVerfi,
.studentVerif {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.princiVerfi > h5 {
  font-size: 14px;
  font-weight: 600;
  color: var(--connected-color);
  margin-left: 10px;
}
.princiVerfi > h3 {
  font-size: 14px;
  font-weight: 600;
  color: red;
  margin-left: 10px;
}
.userabout__InfoDegree {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.userabout__InfoDegree > h3 {
  font-size: 16px;
  font-weight: 700;
  /* color: var(--connected-orange); */
}
.userabout__InfoDegreeDataInfo {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  /* margin-top: 5px; */
}
.userabout__InfoDegreeDataInfo > h5 {
  font-size: 14px;
  font-weight: 700;
  width: 100px;
  text-align: right;
  color: gray;
}
.userabout__InfoDegreeDataInfo > p {
  width: 10px;
  font-size: 14px;
  font-weight: 700;
  margin-left: 10px;
  color: gray;
}
.userabout__InfoDegreeDataInfo > h4 {
  font-size: 14px;
  font-weight: 700;
  max-width: 450px;
  color: black;
}
.userabout__InfoAward {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.userabout__InfoAward > h5 {
  width: 20px;
  font-size: 15px;
  font-weight: 700;
  color: gray;
}
.userabout__InfoAward > p {
  font-size: 15px;
  font-weight: 700;
  max-width: 550px;
  color: gray;
}
.userabout__data:hover {
  text-decoration: underline;
}
