.allMessages {
  height: 100%;
  display: grid;
  background-color: var(--connected-background);
  place-items: center;
}
.allMessagesBlock {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 1000px;
  margin: 5px auto;
}
.noMessagesToShow > h2 {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: var(--connected-orange);
  margin-top: 100px;
  margin-bottom: 100px;
  /* height: 100vh; */
}
.AllMessagesSent > h2,
.AllMessagesRecieved > h2 {
  font-size: 20px;
  font-weight: 600;
  color: var(--connected-orange);
  margin-left: 50px;
  margin-bottom: 20px;
}
