.SchoolUpcomingEvents {
  /* display: flex; */
  /* flex-direction: column; */
  background-color: var(--connected-background);
  height: 100%;
  /* width: 100%; */
  overflow-y: hidden;
}

.SidebarNoticeBoard::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.SidebarNoticeBoard {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.SidebarNoticeBoard__ContainerBottomRight {
  display: flex;
  flex-direction: column;
}

.createEventBlock,
.createEvent__Crowd,
.createEvent__Type {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sentEvent {
  display: flex;
  flex-direction: column;
}

.createEventBlock>h3,
.sentEvent>h3 {
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}

.createEvent__Date {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.createEvent__Date>h5 {
  font-size: 15px;
  font-weight: 700;
  color: var(--connected-orange);
}

.createEvent__Date>h4 {
  font-size: 14px;
  font-weight: 400;
  color: red;
}

.createEvent__Date>input {
  width: 300px;
  height: 40px;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.createEvent__Date>input::-webkit-input-placeholder {
  padding: 10px;
}

.createEvent__ChiefGuests {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.createEvent__ChiefGuestsList {
  display: flex;
  flex-direction: column;
}

.createEvent__ChiefGuestsListRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.createEvent__ChiefGuests>h4 {
  font-size: 15px;
  font-weight: 700;
  color: var(--connected-orange);
}

.ChiefGuest,
.createEvent__CrowdRow,
.createEvent__CrowdRowData {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.ChiefGuest {
  margin-right: 10px;
}

.createEvent__CrowdRowData {
  margin-left: 10px;
  margin-right: 10px;
}

.createEvent__CrowdRowData>input:hover,
.createEvent__CrowdRowData>label:hover {
  cursor: pointer;
}

.createEvent__CrowdRowData>input {
  margin-right: 5px;
}

.createEvent__CrowdRowData>label {
  font-size: 15px;
  font-weight: 400;
  color: black;
}

.ChiefGuest>p {
  font-size: 16px;
  font-weight: 600;
  /* color: var(--connected-color); */
  margin-right: 5px;
}

.createEvent__Description {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.createEvent__Description>h4,
.createEvent__Crowd>h4,
.createEvent__Type {
  font-size: 15px;
  font-weight: 700;
  color: var(--connected-orange);
}

.createEvent__Name {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.createEvent__Name>h3 {
  font-size: 15px;
  font-weight: 700;
  color: var(--connected-orange);
}

.createEvent__Name>h5 {
  font-size: 15px;
  font-weight: 500;
  color: green;
  margin-bottom: 20px;
}

.buttonPublishEvent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonPublishEvent>button {
  color: white;
  background-color: var(--connected-color);
  cursor: pointer;
  border: 1px solid var(--connected-color);
  font-size: 16px;
  font-weight: 700;
  text-transform: inherit;
  border-radius: 4px;
  width: 120px;
  height: 35px;
  margin-top: 10px;
}

.buttonPublishEvent>button:hover {
  color: var(--connected-color);
  background-color: white;
  border: 1px solid var(--connected-color);
}

.buttonPublishEvent>h3 {
  font-size: 18px;
  font-weight: 700;
  color: green;
  margin-top: 10px;
}

.noEvent>h4 {
  font-size: 20px;
  font-weight: 700;
  color: var(--connected-orange);
  text-align: center;
  margin-top: 20px;
}

.limitEvents>h4 {
  font-size: 18px;
  font-weight: 700;
  color: red;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 800px;
}