.userprofile {
  background-color: var(--connected-background);
}
.userprofile__body {
  display: flex;
  flex-direction: column;
  width: 1000px;
  height: 100%;
  width: 100%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.userprofile__bodyTop {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.userprofile__bodyBottom {
  display: flex;
  flex-direction: row;
  width: 900px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.userprofile__bodyBottomRight {
  width: 600px;
  margin-left: 5px;
}
#userprofile__bodyTopNavbarAppBar > .MuiAppBar-root {
  position: sticky;
  top: 70px;
  z-index: 10;
  width: 1000px;
}