/* USERS */
.UsersProfilePic {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
}
.UsersProfilePicleft > h2 {
  font-size: 20px;
  font-weight: 700;
  color: var(--connected-color);
  margin-right: 100px;
}
.UsersProfilePicRight {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.UsersProfilePicRight > button {
  color: white;
  background-color: gray;
  cursor: pointer;
  border: 1px solid gray;
  font-size: 13px;
  font-weight: 700;
  border-radius: 8px;
  text-transform: inherit;
  width: 120px;
  height: 25px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
}
.UsersProfilePicRight > button:hover {
  color: gray;
  background-color: white;
}
.createnewaccountinfo__containerUsers {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.createnewaccountinfo__containerUsers > h3 {
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--connected-color);
}
.createnewaccountinfo__containerUsers > button {
  color: white;
  background-color: var(--connected-color);
  cursor: pointer;
  border: 1px solid var(--connected-color);
  font-size: 16px;
  font-weight: 600;
  text-transform: inherit;
  border-radius: 3px;
  height: 35px;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
}
.createnewaccountinfo__containerUsers > button:hover {
  color: var(--connected-color);
  background-color: white;
  border: 1px solid var(--connected-color);
}
/* USERS */
