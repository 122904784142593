.homeWorksstudentssubject {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.subRowPanel {
  padding: 0 18px;
  background-color: white;
}
.buttonText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.buttonText > h4 {
  color: var(--connected-orange);
  font-size: 18px;
  font-weight: 600;
}
.subRowPanelData {
  margin-top: 10px;
  width: 750px;
  justify-content: center;
}
.subRowPanelData > h3 {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: gray;
}
.subRowPanelDataSegment {
  display: flex;
  flex-direction: column;
}
.subRowPanelDataSegment > h4 {
  font-size: 16px;
  font-weight: 700;
  margin-left: 40px;
  margin-top: 20px;
  margin-bottom: 8px;
  color: var(--connected-color);
}
.accordianFullBody > .MuiAccordion-root > .MuiAccordionSummary-root {
  background-color: rgb(248, 240, 218);
}
.subRowPanelDataAccordianHeading {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: lightgray; */
}
.subRowPanelDataUploadedOn {
  margin-left: 20px;
}
.subRowPanelDataAccordianHeadingHead {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.subRowPanelDataAccordianHeadingHead > h4 {
  font-size: 16px;
  font-weight: 700;
  color: gray;
}
.subRowPanelDataAccordianHeadingHead > h3 {
  font-size: 16px;
  font-weight: 700;
  color: black;
  margin-left: 10px;
  width: 140px;
  /* background-color: red; */
}
.subRowPanelDataAccordianHeadingQuestion > h4 {
  font-size: 16px;
  font-weight: 700;
  color: black;
  width: 700px;
  margin-left: 10px;
  margin-bottom: 8px;
}
.subRowPanelDataAccordianHeadingImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.subRowPanelDataAccordianHeadingImage > center > img {
  max-width: 400px;
  max-height: 400px;
  border-radius: 10px;
}
.subRowPanelDataAccordianDates {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 40px;
}
.subRowPanelDataAccordianDates > h5 {
  font-size: 14px;
  font-weight: 500;
  width: 150px;
  text-align: end;
}
.subRowPanelDataAccordianDates > p {
  font-size: 14px;
  font-weight: 500;
  width: 10px;
  margin-left: 10px;
}
.subRowPanelDataAccordianDates > h4 {
  font-size: 14px;
  font-weight: 700;
  color: black;
}
.noProjectNHomework {
  margin-top: 20px;
  font-size: 15px;
  font-weight: 700;
  color: gray;
  margin-left: 40px;
}
