.sidebarhomeworks {
  background-color: var(--connected-background);
  height: 100%;
}
.sidebarhomeworks__notverified {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
}
.sidebarhomeworks__notverified > h2 {
  font-size: 22px;
  font-weight: 700;
  color: var(--connected-orange);
  text-align: center;
}
