.MessageBody {
  margin-bottom: 10px;
}
.MessageBodyAccordionSummary {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.MessageBodyAccordionSummary > button {
  background-color: var(--connected-color);
  border: 1px solid var(--connected-color);
  color: white;
  text-transform: inherit;
  border-radius: 2px;
  width: 50px;
  height: 25px;
  margin-left: 50px;
  font-size: 14px;
  font-weight: 500;
}
.MessageBodyAccordionSummary > button:hover {
  background-color: white;
  border: 1px solid var(--connected-color);
  color: var(--connected-color);
}
.msgFrom,
.msgOnDate {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.msgFrom > h2,
.msgOnDate > h2 {
  font-size: 16px;
  font-weight: 600;
  color: gray;
  /* text-align: right; */
}
.msgFrom > h2 {
  width: 60px;
}
.msgOnDate > h2 {
  width: 60px;
}
.msgFrom > h3 {
  color: var(--connected-color);
  width: 400px;
  font-size: 16px;
  font-weight: 600;
  margin-right: 20px;
  height: 20px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  /* background-color: red; */
}
.msgOnDate > h3 {
  color: black;
  width: 150px;
  font-size: 16px;
  font-weight: 500;
  margin-right: 20px;
}
#messageBody > .MuiAccordion-root > .MuiAccordionSummary-root {
  background-color: rgb(239, 245, 250);
  padding-left: 50px;
}
#messageBody > .MuiAccordion-root > .MuiAccordionSummary-root:hover {
  background-color: rgb(207, 239, 248);
}
.MessageBodyAccordionDetails {
  width: 900px;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 20px;
}
.msgSentToBody,
.msgSentDateBody,
.msgSentTextBody {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
}
.msgSentToBody > h4,
.msgSentDateBody > h4,
.msgSentTextBody > h4 {
  font-size: 16px;
  font-weight: 600;
  color: var(--connected-orange);
  width: 120px;
  padding: 5px;
  text-align: right;
}
.msgSentToBody > p,
.msgSentDateBody > p,
.msgSentTextBody > p {
  font-size: 16px;
  font-weight: 600;
  color: gray;
  width: 20px;
  padding: 5px;
}
.msgSentToBody > h3,
.msgSentDateBody > h3,
.msgSentTextBody > h3 {
  width: 700px;
  padding: 5px;
}
.msgSentToBody > h3,
.msgSentDateBody > h3 {
  color: gray;
  font-size: 16px;
  font-weight: 600;
}
.msgSentTextBody > h3 {
  /* color: var(--connected-color); */
  font-size: 16px;
  font-weight: 600;
}
.msgSentToBody > h3:hover {
  text-decoration: underline;
}
.msgDeleted{
  width: 100%;
  border: 1px solid grey;
  border-radius: 10px;
  padding: 10px;
}
.msgDeleted>h3{
  color: var(--connected-orange);
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}
