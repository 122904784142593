.UserNoticeBoard {

  background-color: var(--connected-background);
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}

.UserNoticeBoard__Container {
  width: 1000px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid lightgray;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.UserNoticeBoard__Container>h3 {
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  color: var(--connected-color);
  margin-top: 20px;
}

.UserNoticeBoard__Container>h4 {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: var(--connected-orange);
}

.UserNoticeBoard__Container>hr {
  border: 1px solid var(--connected-color);
  width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  margin-top: 20px;
}

.noNoticesYet>h3,
.notVerifiedYet>h3 {
  font-size: 20p;
  font-weight: 700;
  color: var(--connected-orange);
  text-align: center;
}