.homepage__body {
  display: flex;
  flex-direction: row;
  width: 1300px;
  background-color: var(--connected-background);
  height: 100%;
  width: 100%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.homepage__bodyLeft {
  width: 300px;
  padding-top: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
}
.homepage__bodyRight {
  width: 600px;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
}
.schoolhomepage {
  /* background-color: chartreuse; */
}
.schoolhomepage__body {
  display: flex;
  flex-direction: row;
  width: 1300px;
  background-color: var(--connected-background);
  height: 100%;
  width: 100%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.schoolhomepage__bodyLeft {
  width: 300px;
  padding-top: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
}
.schoolhomepage__bodyRight {
  width: 600px;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
}
