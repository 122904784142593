/* STUDENT */

.createnewaccountinfo__containerStudent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.createnewaccountinfo__containerStudent > h3 {
  font-size: 24px;
  font-weight: 700;
  color: var(--connected-color);
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}
.h5__schooName > h5,
.h5__Class > h5,
.h5__section > h5,
.h5__medium > h5 {
  font-size: 13px;
  font-weight: 600;
  color: red;
  margin-left: 20px;
  text-align: justify;
}
.h5__schooName > h5 {
  width: 600px;
}
.h5__medium {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.StudentsProfilePic {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 40px;
}
.StudentsProfilePicleft > h2 {
  font-size: 20px;
  font-weight: 700;
  color: gray;
  margin-right: 100px;
}
.StudentsProfilePicRight {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.StudentsProfilePicRight > button {
  color: white;
  background-color: gray;
  cursor: pointer;
  border: 1px solid gray;
  font-size: 13px;
  font-weight: 700;
  border-radius: 4px;
  text-transform: inherit;
  width: 120px;
  height: 25px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
}
.StudentsProfilePicRight > button:hover {
  color: gray;
  background-color: white;
}
.h5__schooName {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.returnedOptions {
  display: flex;
  flex-direction: column;
  width: 630px;
  border: 1px solid lightgray;
  margin-top: 5px;
  border-radius: 4px;
  padding-bottom: 5px;
}
.optionToBeSelected {
  display: flex;
  flex-direction: column;
}
.optionToBeSelected > p {
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  cursor: pointer;
}
.optionToBeSelected > p:hover {
  text-decoration: underline;
}
.createnewaccountinfo__containerStudent > input {
  width: 700px;
  height: 40px;
  border: 1px solid gray;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  padding-left: 15px;
}
.createnewaccountinfo__containerStudent > input::-webkit-input-placeholder {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
.ClassnSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}
.ClassNum,
.ClassSection {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.createnewaccountinfo__containerStudent > button {
  color: white;
  background-color: var(--connected-color);
  cursor: pointer;
  border: 1px solid var(--connected-color);
  font-size: 17px;
  font-weight: 600;
  text-transform: inherit;
  border-radius: 3px;
  width: 400px;
  height: 35px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}
.createnewaccountinfo__containerStudent > button:hover {
  color: var(--connected-color);
  background-color: white;
  border: 1px solid var(--connected-color);
}
.createnewaccountinfostudents {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.createnewaccountinfostudents > h5 {
  color: green;
  font-size: 16px;
  font-weight: 700;
}
.createnewaccountinfostudents > button {
  color: white;
  background-color: var(--connected-color);
  cursor: pointer;
  border: 1px solid var(--connected-color);
  font-size: 17px;
  font-weight: 600;
  text-transform: inherit;
  border-radius: 3px;
  width: 200px;
  margin-top: 10px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
}
.createnewaccountinfostudents > button:hover {
  color: var(--connected-color);
  background-color: white;
  border: 1px solid var(--connected-color);
}
.students__radioMediumMuiDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}
.students__radioMediumMui {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
}
.noAvailable {
  width: 630px;
}
.noAvailable > h5 {
  font-size: 13px;
  font-weight: 400;
  color: var(--connected-orange);
}
.createnewaccountinfo__containerStudent > h4 {
  font-size: 20px;
  font-weight: 700;
  color: red;
  text-align: center;
}
/* STUDENT */
