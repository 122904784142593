.notCreatedPost {
  padding: 40px 60px;
  border: 1px solid lightgray;
  background-color: white;
}
.notCreatedPost > h3 {
  text-align: center;
  color: var(--connected-orange);
  font-size: 20px;
  font-weight: 700;
}
.tabPanelHomePageFeed {
  width: 600px;
  border: 1px solid lightgray;
  border-radius: 3px;
}
.loadMoreText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loadMoreText > button, .showMoreBtn>button {
  background-color: var(--connected-color);
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-transform: inherit;
  border-radius: 3px;
  height: 30px;
  width: 200px;
  margin: 20px;
}
.loadMoreText > button:hover, .showMoreBtn>button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}
.noMorePosts > h4 {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  color: var(--connected-orange);
  margin: 20px;
}
.tabPanelHomePageFeed {
  position: sticky;
  top: 70px;
  z-index: 1;
}
.showMoreBtn{
display:flex;
flex-direction: column;
align-items: center;
}