.educationSidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.educationSidebar__top {
  /* padding-top: 15px; */
}

.educationSidebar__option {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 30px;
}

.educationSidebar__option:hover {
  background-color: var(--connected-color);
  color: white !important;
  border-radius: 4px;
}

.educationSidebar__option>p {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 700;
}

.educationSidebar__option>.MuiSvgIcon-root,
.educationSidebar__optionNew>.MuiSvgIcon-root {
  font-size: 20px;
}