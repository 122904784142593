.HomeWorksPrincipalClassNSubject {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.princiSubRow {
  width: 900px;
}
.princiSubRow > .MuiAccordion-root > .MuiAccordionSummary-root {
  background-color: rgb(250, 239, 239);
  padding-left: 50px;
  margin-bottom: 10px;
}
.princiSubRow > .MuiAccordion-root > .MuiAccordionSummary-root:hover {
  background-color: rgb(247, 224, 190);
}
.princiButtonText > h4 {
  color: var(--connected-orange);
  font-size: 18px;
  font-weight: 600;
}
.princiSubRowPanelData {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.princiSubRowPanelData > h3 {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}
.subjectClass {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.subjectClass > .MuiAccordion-root {
  width: 850px;
}
.subjectClass > .MuiAccordion-root > .MuiAccordionSummary-root {
  background-color: rgb(236, 236, 248);
}
.subjectClass > .MuiAccordion-root > .MuiAccordionSummary-root:hover {
  background-color: rgb(210, 210, 247);
}
.subAccordionSummary > h3 {
  color: var(--connected-color);
  margin-left: 50px;
  font-size: 18px;
  font-weight: 600;
}
.subAccordionDetails > h3 {
  margin-left: 40px;
  margin-bottom: 20px;
  color: gray;
}
.subAccordDetHAP {
  margin-bottom: 10px;
}
.subAccordDetHAP > h4 {
  font-size: 18px;
  font-weight: 700;
  color: var(--connected-orange);
  margin-left: 100px;
  margin-bottom: 10px;
}
