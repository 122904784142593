.SidebarESchoolingStatusShowParent {
  /* background-color: blue; */
}
.SidebarESchoolingStatus__ContainerBottomRightShowParent {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
.rowData {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}
.rowData > h2 {
  font-size: 18px;
  font-weight: 700;
  width: 300px;
  color: gray;
  text-align: end;
}
.rowData > h3 {
  font-size: 20px;
  font-weight: 700;
  width: 500px;
}
.rowData > h4 {
  margin-left: 20px;
  width: 30px;
  color: gray;
}
.accordianTop > button {
  background-color: var(--connected-color);
  border: none;
  color: white;
  font-weight: 700;
  text-transform: inherit;
  border-radius: 4px;
  width: 70px;
  height: 25px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
}
.accordianTop > button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}
.previouslyAddedChild > h3,
.addedNewChild > h3 {
  margin-left: 100px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  color: var(--connected-orange);
}
.addedNewChild {
  margin-top: 20px;
}
.accordianTop,
.accordianTopClass,
.accordianTopName {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.accordianTop > h4 {
  width: 170px;
  font-size: 16px;
  font-weight: 700;
  color: var(--connected-color);
  text-align: center;
}
.accordianTopName > h3 {
  width: 60px;
  font-size: 16px;
  font-weight: 700;
  margin-left: 10px;
  color: var(--connected-color);
}
.accordianTopName > h4 {
  width: 200px;
  font-size: 16px;
  color: black;
}
.accordianTopClass {
  /* width: 130px; */
}
.accordianTopClass > h5 {
  font-size: 16px;
  color: var(--connected-color);
  font-weight: 700;
  width: 60px;
}
.accordianTopClass > p {
  font-size: 16px;
  font-weight: 700;
  color: black;
  width: 170px;
}
.AccordionDetailsData {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  margin-bottom: 20px;
  margin-left: 20px;
}
.AccordionDetailsData > h4 {
  width: 180px;
  font-size: 20px;
  color: var(--connected-orange);
  font-weight: 700;
}
.AccordionDetailsData > h5 {
  width: 20px;
  font-size: 20px;
  color: gray;
  font-weight: 700;
}
.AccordionDetailsData > p {
  width: 500px;
  font-size: 20px;
  color: black;
  font-weight: 700;
  /* background-color: red; */
}
.addNewChildDataHereTop > h3 {
  font-size: 18px;
  color: gray;
  font-weight: 700;
  margin-left: 20px;
}
.addNewChildDataHere {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.addNewChildDataHereName,
.addNewChildDataHereClassNSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.addNewChildDataHereClassNSection {
  margin-top: 20px;
}
.addNewChildDataHere > hr {
  width: 700px !important;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid var(--connected-color);
}
.addNewChildDataHere > button,
.addNewChildDataHereData > button {
  background-color: var(--connected-color);
  border: none;
  color: white;
  font-size: 15px;
  font-weight: 700;
  text-transform: inherit;
  border-radius: 4px;
  width: 300px;
  height: 35px;
  margin-top: 20px;
}
.addNewChildDataHere > button:hover,
.addNewChildDataHereData > button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}
.addNewChildDataHereRequestSent > h3 {
  font-size: 15px;
  font-weight: 700;
  color: green;
}
.exceededLimit {
  margin-left: 160px;
  margin-top: 40px;
}
.exceededLimit > h3 {
  font-size: 20px;
  font-weight: 700;
  color: var(--connected-orange);
}
.SidebarESchoolingStatusShowParentChild
  > .MuiAccordion-root
  > .MuiAccordionSummary-root {
  background-color: rgb(235, 234, 234);
}
.SidebarESchoolingStatusShowParentChild
  > .MuiAccordion-root
  > .MuiAccordionSummary-root:hover {
  background-color: rgb(190, 189, 189);
}
#addedNewChild > .MuiAccordion-root > .MuiAccordionSummary-root {
  background-color: rgb(252, 240, 221);
}
#addedNewChild > .MuiAccordion-root > .MuiAccordionSummary-root:hover {
  background-color: rgb(248, 214, 182);
}
