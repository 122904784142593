.SidebarESchoolingStatusShowPrincipal {
}

.SidebarESchoolingStatus__ContainerBottomRightShowPrincipal {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
.rowData {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}
.rowData > h2 {
  font-size: 18px;
  font-weight: 700;
  width: 300px;
  color: gray;
  text-align: end;
}
.rowData > h3 {
  font-size: 20px;
  font-weight: 700;
  width: 500px;
}
.rowData > h4 {
  margin-left: 20px;
  width: 30px;
  color: gray;
}

.princiButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.princiButton > button {
  background-color: var(--connected-color);
  border: none;
  color: white;
  font-weight: 700;
  text-transform: inherit;
  border-radius: 4px;
  width: 70px;
  height: 30px;
  margin-left: 10px;
}

.princiButton > button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}
