.SchoolTimeTableSchoolClsNSubUpload {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.recentlyUploaded {
    margin-bottom: 20px;
}

.recentlyUploaded>img,
.uploadBtnImg>img {
    margin: 10px;
    width: 300px;
    border-radius: 10px;
    cursor: pointer;
}

.recentlyUploaded>h4,
.updateNew>h4 {
    font-size: 18px;
    font-weight: 700;
    margin: 10px 0px 10px 30px;
    color: var(--connected-orange);
}

.uploadBtnInput {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.uploadBtnInput>button {
    margin: 10px 0px 0px 30px;
}

.uploadBtnImg {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.uploadBtnImg>button {
    margin: 10px;
    width: 300px;
    height: 40px;
    font-weight: 700;
    font-size: 16px;
    background-color: var(--connected-color);
    color: white;
    border: 1px solid white;
    border-radius: 4px;
}

.uploadBtnImg>button:hover {
    cursor: pointer;
    background-color: white;
    color: var(--connected-color);
    border: 1px solid var(--connected-color);
}

.uploadBtnImg>h3 {
    font-size: 18px;
    font-weight: 700;
    margin: 10px;
    color: green;
}