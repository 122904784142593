.HomePageSidebarParents {
  /* margin-top: -20px; */
}

.HomePageSidebarParentssidebar__infoStatus {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.HomePageSidebarParentssidebar__infoStatus>p {
  font-size: 13px;
  font-weight: 500;
  color: gray;
}

.Of>h4,
.at>h4 {
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
  color: var(--connected-orange);
}

.childNameArrow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.parentsChildsName {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  padding-left: 10px;
}

.parentsChildsName>h2 {
  font-size: 16px;
  font-weight: 700;
  margin-left: 10px;
  text-align: start;
}

.arrowDropdown {
  width: 30px;
  margin-right: 10px;
}

.arrowDropdown>.MuiSvgIcon-root {
  font-size: 20px !important;
  padding-top: 8px;
}

.arrowDropdown>.MuiSvgIcon-root:hover {
  color: var(--connected-color);
  cursor: pointer;
}

.tableOfClass {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
}

.tableOfClass>h4 {
  font-size: 13px;
  font-weight: 700;
  color: gray;
  /* text-align: center; */
}

.tableOfClass>h3 {
  font-size: 13px;
  font-weight: 700;
  color: gray;
  /* text-align: center; */
}

.at {
  margin-bottom: 5px;
}

.childSchoolName {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
}

.childSchoolName:hover {
  cursor: pointer;
  text-decoration: underline;
}

.childSchoolName>h5 {
  font-size: 14px;
  font-weight: 700;
  margin-left: 10px;
  text-align: left;
  color: gray !important;
}

#childsListDropDown {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 10px; */
  padding-left: 20px;
  margin-bottom: 8px;
  /* margin-bottom: 10px; */
}

#childsListDropDown:hover {
  cursor: pointer;
  color: var(--connected-color);
}

#childsListDropDown>h2 {
  font-size: 15px;
  font-weight: 700;
  margin-left: 10px;
  text-align: start;
}

.childsData {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border-top: 1px solid lightgray; */
  /* border-bottom: 1px solid lightgray; */
  /* border-radius: 4px; */
  /* margin-top: 5px; */
  /* margin-left: 3px; */
  /* margin-right: 3px; */
  padding-top: 10px;
  padding-bottom: 10px;
}