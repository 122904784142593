/* PARENTS */
.schoolverificationrequests__containerRightParentsGuardiansData {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-left: 20px;
  width: 100%;
}
.schoolverificationrequests__containerRightParentsGuardiansDataName {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.schoolverificationrequests__containerRightParentsGuardiansData > p {
  font-size: 16px;
  font-weight: 800;
  color: var(--connected-color);
  width: 200px;
  margin-right: 10px;
}
.schoolverificationrequests__containerRightParentsGuardiansData > h5 {
  font-size: 13px;
  font-weight: 800;
  color: var(--connected-orange);
  width: 150px;
  /* margin-left: 20px; */
  margin-right: 10px;
  /* background-color: yellow; */
}
.schoolverificationrequests__containerRightParentsGuardiansDataName > h3 {
  font-size: 16px;
  font-weight: 700;
  width: 230px;
  margin-left: 10px;
}
.dataNameParent > h3:hover {
  cursor: pointer;
  text-decoration: underline;
}
.schoolverificationrequests__containerRightParentsGuardiansDataButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.schoolverificationrequests__containerRightParentsGuardiansDataButtons
  > button {
  background-color: var(--connected-color);
  border: none;
  color: white;
  font-weight: 600;
  text-transform: inherit;
  border-radius: 4px;
  width: 60px;
  height: 30px;
  margin-left: 10px;
  font-size: 13px;
}
.schoolverificationrequests__containerRightParentsGuardiansDataButtons
  > button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}
/* PARENTS */
