.SidebarESchoolingStatus {
  background-color: var(--connected-background);
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}

.SidebarESchoolingStatus__Container {
  width: 1200px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid lightgray;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 10px;
  height: 100%;
}

.SidebarESchoolingStatus__ContainerTop {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SidebarESchoolingStatus__ContainerTop>hr {
  width: 100% !important;
  margin-bottom: 20px;
  margin-top: 20px;
  border: 1px solid var(--connected-color);
}

.SidebarESchoolingStatus__ContainerTop>h2 {
  font-size: 22px;
  font-weight: 800;
  color: var(--connected-orange);
}

.SidebarESchoolingStatus__ContainerBottom {
  display: flex;
  flex-direction: row;
}

.SidebarESchoolingStatus__ContainerBottomLeft {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  position: sticky;
  top: 80px;
}

.SidebarESchoolingStatus__ContainerBottomRight {
  width: 900px;
}

.AddStatus,
.existingStatus {
  display: flex;
  flex-direction: column;
}

.statusTitle,
.addStatusTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
  padding-top: 5px;
  background-color: var(--connected-color);
  color: white;
  cursor: pointer;
}

.statusTitle>h2,
.addStatusTitle>h2 {
  font-size: 17px;
  font-weight: 700;
  margin-left: 10px;
}

.statusTitle>.MuiSvgIcon-root,
.addStatusTitle>.MuiSvgIcon-root {
  font-size: 25px;
  margin-right: 7px;
}

.existingESchoolStatus {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.showExistingESchoolStatus,
.addExistingESchoolStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.showExistingESchoolStatus:hover,
.addExistingESchoolStatus:hover {
  /* color: var(--connected-color); */
  text-decoration: underline;
}

.showExistingESchoolStatus>h2,
.addExistingESchoolStatus>h2 {
  font-size: 16px;
  font-weight: 700;
  color: gray;
}

.showExistingESchoolStatus>.MuiSvgIcon-root,
.addExistingESchoolStatus>.MuiSvgIcon-root {
  /* font-size: 25px; */
  color: gray;
}

.yesNoStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 100px;
}

.yesNoStatus>h3 {
  font-size: 22px;
  font-weight: 600;
  margin-right: 20px;
}

.SidebarESchoolingStatusAddStatusYesNo {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}