.FindToFollow {
  /* display: flex; */
  /* flex-direction: column; */
  background-color: var(--connected-background);
  height: 100%;
  /* width: 100%; */
  overflow-y: hidden;
}

.FindToFollow__Container {
  width: 1200px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid lightgray;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 10px;
  height: 100%;
}