.createschoolaccount {
  height: 100%;
  display: grid;
  background-color: var(--connected-background);
  place-items: center;
}
.createschoolaccount__container {
  margin-left: auto;
  margin-right: auto;
  border: 1px solid lightgray;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 80px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.createschoolaccount__container > h1 {
  font-size: 50px;
  font-weight: 700;
  color: var(--connected-color);
}
.createschoolaccount__container > p {
  font-size: 18px;
  font-weight: 400;
  color: gray;
  margin-left: 20px;
}
.createschoolaccount__container > hr {
  width: 100%;
  border: 1px solid var(--connected-color);
  margin-bottom: 10px;
  margin-top: 20px;
}
.createschoolaccount__containerTablesData {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* ////////////////////////////////////////////////////////////////////////////////////////////////////// */
.createnewaccount__note {
  display: flex;
  flex-direction: column;
  width: 880px;
  border-radius: 10px;
  border: 1px solid lightgray;
  padding: 10px;
}
.extraDiv {
  height: 70px;
}
.createnewaccount__note > h3,
.note > h3 {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--connected-orange);
}
.createnewaccount__noteData {
  display: flex;
  flex-direction: column;
}
.createnewaccount__noteData > h4 {
  font-size: 16px;
  font-weight: 700;
  margin-left: 50px;
  color: black;
}
.createnewaccount__noteDataexamples {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}
.createnewaccount__noteDataexamples > p {
  font-size: 14px;
  font-weight: 500;
  margin-left: 100px;
  color: gray;
}
.createnewaccount__noteDataexamples > h5 {
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
  margin-left: 50px;
  margin-bottom: 10px;
}
.dummyDataAccordian {
  margin-top: 20px;
  margin-bottom: 20px;
}
.dummyDataAccordian > .MuiAccordion-root > .MuiAccordionSummary-root {
  background-color: rgb(224, 224, 240);
}
.dummyDataAccordian > .MuiAccordion-root > .MuiAccordionSummary-root:hover {
  background-color: rgb(192, 192, 229);
}
.howToSelect > h3 {
  font-size: 18px;
  font-weight: 700;
  color: brown;
  margin-left: 50px;
  padding: 5px;
}
.dummytable {
  width: 850px !important;
  margin-top: 0px !important;
  border-radius: 15px;
  padding: 10px;
}
.dummytable > tr,
.dummytable > th,
.dummytable > td {
  align-items: center;
  justify-content: center;
  align-content: center;
  /* border: 1px solid gray; */
}
.dummytable > tbody > tr > td,
.dummytable > thead > tr > th {
  /* background-color: var(--connected-background); */
  border: 1px solid lightgrey;
  color: gray;
}
.dummytable > tbody > tr > td > input {
  margin-left: 25px;
  margin-bottom: 10px;
}
.checkbox__heading {
  text-align: center;
  margin-top: 50;
}
.Classtable > thead > tr > th,
.Classtable > tbody > tr > td {
  padding: 7px;
}
.Classtable {
  width: 900px;
  margin-bottom: 20px;
}
.Classtable > thead > tr > th {
  height: 50px;
  background-color: var(--connected-color);
}
.Classtable > tbody > tr > td {
  text-align: center;
  background-color: var(--connected-background);
  /* padding: 10px; */
}
.cls {
  text-align: start;
  /* margin-right: 20px; */
}
.cls > p {
  text-align: start;
  /* margin-right: 20px; */
}
.Classtable__subheading > th > p {
  color: white;
  font-size: 16px;
  font-weight: 700;
}
.Classtable__mainheading > th > p {
  font-size: 16px;
  font-weight: 700;
  color: white;
}
.Classtable > tbody > tr > td > input {
  cursor: pointer;
}
.Classtable__subheading > th > p {
  font-size: 14px;
  font-weight: 500;
}
.SubmitButton > button {
  color: white;
  background-color: var(--connected-color);
  cursor: pointer;
  border: 1px solid var(--connected-color);
  font-size: 13px;
  font-weight: 700;
  text-transform: inherit;
  border-radius: 4px;
  width: 330px;
  height: 40px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
}
.SubmitButton > button:hover {
  color: var(--connected-color);
  background-color: white;
  border: 1px solid var(--connected-color);
}
.classSubjects {
  width: 900px;
}
.classSubjects > h2 {
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  /* color: var(--connected-color); */
  margin-top: 70px;
}
.classnSubjects__table {
  width: 900px;
  margin-top: 20px;
  margin-bottom: 20px;
  /* margin-left: auto;
  margin-right: auto; */
}
.classnSubjects__table > thead > tr > th {
  height: 50px;
  background-color: var(--connected-color);
}
.classnSubjects__table > thead > tr > th > p {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  background-color: var(--connected-color);
  color: white;
}
.classnSubjects__table > tbody > tr {
}
.classnSubjects__table > tbody > tr > td {
  /* text-align: center; */
  padding: 5px;
}
.classnSubjects__tableHeadingMedium {
  width: 80px;
}
.classnSubjects__tableHeadingClass {
  width: 165px;
}
.classnSubjects__tableHeadingSection {
  width: 90px;
}
.classnSubjects__tableBodyMedium {
  font-size: 16px;
  font-weight: 600;
}
.classnSubjects__tableBodyClass {
  font-size: 16px;
  font-weight: 600;
}
.classnSubjects__tableBodySection {
  font-size: 16px;
  font-weight: 600;
}
.classSubjects__dataClassTotalsubjects {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
}
.classSubjects__dataClassTotalsubjects > label {
  font-size: 16px;
  font-weight: 600;
  color: rgb(95, 94, 94);
  width: 440px;
}
.classSubjects__dataClassTotalsubjects > select {
  width: 50px;
  margin-left: 10px;
}
.classSubjects__dataClassTotalsubjectsInputs {
  display: inline;
  left: 0;
  justify-content: flex-start;
  margin-top: 5px;
}
.error_message {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.error_message > h5 {
  font-size: 16px;
  font-weight: 700;
  color: red;
}

.MuiFormHelperText-root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.subNumber > p {
  font-size: 14px;
  margin: 0 5px;
  color: var(--connected-color);
  font-weight: 700;
}
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
