.createnewaccount {
  height: 100%;
  display: grid;
  background-color: var(--connected-background);
  place-items: center;
}
.createnewaccount__container {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 80px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.createnewaccount__container > h1 {
  font-size: 50px;
  font-weight: 700;
  color: var(--connected-color);
}
.createnewaccount__container > p {
  font-size: 18px;
  font-weight: 400;
  color: gray;
  margin-left: 20px;
}
.createnewaccount__container > hr {
  width: 100%;
  border: 1px solid var(--connected-color);
  margin-bottom: 20px;
  margin-top: 20px;
}
.createnewaccount__containerh4 > h4 {
  font-size: 14px;
  font-weight: 500;
  width: 180px;
  height: 20px;
  color: var(--connected-color);
  text-align: center;
  border: 1px solid white;
  border-radius: 10px;
  cursor: pointer;
}
.createnewaccount__containerh4 > h4:hover {
  background-color: var(--connected-background);
  border: 1px solid var(--connected-background);
}
.createnewaccount__name {
  display: flex;
  flex-direction: row;
}
.h5__FName > h5,
.h5__LName > h5,
.h5__Email > h5,
.h5__password > h5,
.h5__passwordConfrim > h5,
.h5__DOB > h5,
.h5__Gender > h5,
.h5__mobile > h5 {
  font-size: 12px;
  font-weight: 600;
  color: red;
  margin-left: 30px;
  text-align: justify;
  margin-bottom: -5px;
}
.createnewaccount__emailmob {
  display: flex;
  flex-direction: column;
}
.createnewaccount__passwords {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.createnewaccount__birthdate {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-bottom: 10px;
  margin-left: 10px;
  align-items: center;
}
.createnewaccount__birthdate > h5 {
  color: var(--connected-color);
  font-size: 18px;
  font-weight: 700;
  margin-right: 10px;
}

.createnewaccount__birthdate > input {
  border: 1px solid lightgray;
  font-size: 14px;
  color: gray;
  font-weight: 700;
  border-radius: 3px;
  width: 200px;
  height: 40px;
  cursor: pointer;
  padding-left: 20px;
}
.createnewaccount__gendersdiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}
.createnewaccount__gendersdiv > h4 {
  color: var(--connected-color);
  /* margin-left: 70px; */
  font-size: 18px;
  font-weight: 700;
}
.students__radioGenderMui {
  margin-left: 20px;
}
.createnewaccount__genders > label {
  font-size: 15px;
  cursor: pointer;
  font-weight: 500;
  margin-left: 5px;
  /* width: 40px; */
  color: gray;
}
.createnewaccount__genders > input {
  margin-left: 20px;
  cursor: pointer;
}
.createnewaccount__containerButton > button {
  color: white;
  background-color: var(--connected-color);
  cursor: pointer;
  border: 1px solid var(--connected-color);
  font-size: 13px;
  font-weight: 700;
  text-transform: inherit;
  border-radius: 4px;
  width: 300px;
  height: 40px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
}
.createnewaccount__containerButton > button:hover {
  color: var(--connected-color);
  background-color: white;
  border: 1px solid var(--connected-color);
}
.MuiFormHelperText-root > p {
  font-size: 12px;
  font-weight: 500;
  color: red;
  margin-left: 30px;
  text-align: justify;
  margin-bottom: -5px;
}
