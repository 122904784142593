.login {
  height: 100vh;
  display: grid;
  background-color: var(--connected-background);
  place-items: center;
}

.login__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 80px;
  text-align: center;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 10px;
  margin-top: 10px;
}

.loginLogo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.loginLogo>h1 {
  font-size: 50px;
  font-weight: 700;
  color: var(--connected-color);
  margin-left: 10px;
}

.login__container>p {
  color: gray;
  font-size: 13px;
}

.login__container>h4 {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
}

.login__containerInputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.login__containerInputs>p {
  font-size: 14px;
  font-weight: 500;
  color: red;
  margin-bottom: 5px;
}

.login__containerInputs>h4 {
  font-size: 14px;
  font-weight: 500;
  color: var(--connected-color);
  cursor: pointer;
  width: 150px;
  height: 20px;
  border: 1px solid white;
  border-radius: 4px;
  margin-top: 10px;
}

.login__containerInputs>h4:hover {
  background-color: var(--connected-background);
  border: 1px solid var(--connected-background);
  text-decoration: underline;
}

.login_username>h5,
.login_password>h5 {
  font-size: 12px;
  font-weight: 600;
  color: red;
  width: 350px;
  margin-left: 30px;
  text-align: justify;
  margin-bottom: -5px;
}

.login__container>form>button {
  text-transform: inherit !important;
  background-color: var(--connected-color) !important;
  color: white;
  margin-bottom: 10px;
  border: 1px solid var(--connected-color);
  font-size: 16px;
  font-weight: 600;
  width: 120px;
  height: 40px;
  border-radius: 4px;
}

.login__container>form>button:hover {
  background-color: white !important;
  color: var(--connected-color) !important;
  border: 1px solid var(--connected-color);
}

.login__container>hr {
  width: 700px !important;
  border: 1px solid var(--connected-color);
}

.login__containerCreateButton>button {
  margin-top: 10px;
  text-transform: inherit !important;
  background-color: rgb(37, 107, 37);
  color: white;
  width: 300px;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid rgb(37, 107, 37);
  height: 40px;
  border-radius: 4px;
}

.login__containerCreateButton>button:hover {
  background-color: white;
  color: rgb(37, 107, 37);
  border: 1px solid rgb(37, 107, 37);
}

/* .login__container__h5 > h5 {
  font-size: 14px;
  font-weight: 500;
  color: var(--connected-color);
  cursor: pointer;
  width: 200px;
  height: 20px;
  border: 1px solid white;
  border-radius: 4px;
  text-decoration: none;
} */
/* .login__container__h5 > h5:hover {
  background-color: var(--connected-background);
  border: 1px solid var(--connected-background);
  text-decoration: underline;
} */