.schoolprofilesidebar {
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  border-radius: 4px;
  background-color: white;
  padding: 10px 5px;
  position: sticky;
  top: 125px;
}
.schoolprofilesidebar__bio {
  padding: 10px;
}
.schoolprofilesidebar__bio > p {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  /* color: gray; */
}
.schoolprofilesidebar__bio > h4 {
  font-size: 16px;
  font-weight: 700;
  color: var(--connected-color);
}
.schoolprofilesidebar__intro {
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: gray;
  border-bottom: 1px solid lightgray;
}
.schoolprofilesidebar__intro > h4 {
  font-size: 16px;
  font-weight: 700;
  color: var(--connected-color);
}
.schoolprofilesidebar__introOptions {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.schoolprofilesidebar__introOption {
  display: flex;
  flex-direction: row;
  padding: 5px;
  /* align-items: center; */
  justify-content: flex-start;
  /* max-width: 200px; */
}
.schoolprofilesidebar__introOption > p {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: rgb(39, 39, 39);
}
.schoolprofilesidebar__introOption > .MuiSvgIcon-root {
  font-size: 20px;
  color: black;
}
