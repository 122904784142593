.SidebarESchoolingStatusAddTeacher {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px;
  margin-left: 40px;
  margin-top: 20px;
}
.SidebarESchoolingStatusAddTeacher > h5 {
  font-size: 18px;
  font-weight: 700;
  color: green;
}
.SidebarESchoolingStatusAddTeacher > button {
  background-color: var(--connected-color);
  color: white;
  font-weight: 700;
  text-transform: inherit;
  border-radius: 4px;
  width: 400px;
  height: 30px;
  font-size: 15px;
  font-weight: 700;
  margin-top: 20px;
}
.SidebarESchoolingStatusAddTeacher > button:hover {
  background-color: white;
  color: var(--connected-color);
  border: 1px solid var(--connected-color);
}
